<template>
    <Layout>
        <div
            class="demo-slider-area slider-area bg-transparent slider-style-1 white"
        >
            <div class="mb--20" data-aos="fade-up">
                <img src="../assets/images/back.png.png" alt="Doob Vue" />
            </div>
        </div>
        <div class="container">
            <div class="row" style="padding: 30px 0">
                <div class="col-lg-12 col-md-12 col-12 whiteBack">
                    <p
                        v-for="(item, index) in 30"
                        :class="index == 0 ? 'active_red' : ''"
                        :key="item.id"
                    >
                        全国
                    </p>
                </div>
            </div>
        </div>
        <div>
            <div class="container">
                <div class="row" style="padding: 30px 0">
                    <div
                        class="col-lg-6 col-md-6 col-12"
                        style="background-color: #fff"
                    >
                        <div class="Leftcard_title">内参头条</div>
                        <div class="Leftcard_content">
                            <p class="red content_title">
                                低年级参考 | 2022清北强基VS统招数据分析
                            </p>
                            <p class="red">
                                最新 | 上海市2022年强基计划录取分数线汇总
                            </p>
                            <p>最新 | 上海市2022年强基计划录取分数线汇总</p>
                            <p>最新 | 上海市2022年强基计划录取分数线汇总</p>
                        </div>
                        <div class="Leftcard_content">
                            <p class="red content_title">
                                低年级参考 | 2022清北强基VS统招数据分析
                            </p>
                            <p class="red">
                                最新 | 上海市2022年强基计划录取分数线汇总
                            </p>
                            <p>最新 | 上海市2022年强基计划录取分数线汇总</p>
                            <p>最新 | 上海市2022年强基计划录取分数线汇总</p>
                        </div>
                        <div class="Leftcard_content">
                            <p class="red content_title">
                                低年级参考 | 2022清北强基VS统招数据分析
                            </p>
                            <p class="red">
                                最新 | 上海市2022年强基计划录取分数线汇总
                            </p>
                            <p>最新 | 上海市2022年强基计划录取分数线汇总</p>
                            <p>最新 | 上海市2022年强基计划录取分数线汇总</p>
                        </div>
                    </div>
                    <div
                        class="col-lg-6 col-md-6 col-12"
                        style="background-color: #fff"
                    >
                        <div class="consult_title">
                            <p class="consult_title_">高考咨询</p>
                            <p class="lookmore">查看更多</p>
                        </div>
                        <div
                            class="new_list"
                            v-for="(item, index) in 9"
                            :key="index"
                        >
                            <p class="new_title">
                                吉林大学韩喜平教授应邀为学校学工干部作专吉林大学韩喜平教授应邀为学校学工干部作专
                            </p>
                            <p class="new_date">2022-07-26</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>

<script>
import Layout from "../components/common/Layout";
import Breadcrumb from "../components/elements/breadcrumb/Breadcrumb";
import SectionTitle from "../components/elements/sectionTitle/SectionTitle";
import Icon from "../components/icon/Icon";
import GoogleMap from "../components/elements/contact/GoogleMap";
import ContactForm from "../components/elements/contact/ContactForm";

export default {
    name: "ContactPage",
    components: {
        ContactForm,
        GoogleMap,
        Icon,
        SectionTitle,
        Breadcrumb,
        Layout,
    },
};
</script>
<style>
.whiteBack {
    background-color: #fff;
    border-radius: 5px;
    display: flex;
    flex-wrap: wrap;
    padding: 20px 0;
}

.whiteBack p {
    width: auto;
    margin: 0%;
    opacity: 1;
    border: 0.85px solid rgba(179, 171, 171, 1);
    color: rgba(87, 87, 87, 1);
    border-radius: 5px;
    padding: 5px 14px;
    margin: 8px 15px;
    font-size: 13px;
    cursor: pointer;
}

.active_red {
    background-color: rgba(97, 171, 255, 1);
    color: #fff !important;
}

.left {
    background-color: white;
    border-radius: 10px;
}

.right {
    background-color: white;
    border-radius: 10px;
}

/* 	@media (min-width: 992px)
	<style>
	.col-lg-4 {
	    flex: 0 0 auto;
	    width: 31.5% !important;
	    margin: 10px;
	} */
</style>
