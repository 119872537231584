<template>
    <Layout header-class="" :show-newsletter="true" :show-cta="false">
        <div class="line"></div>
        <div class="rn-demo-area" id="demo">
            <div class="container">
                <div class="row" style="">
                    <div class="col-lg-3 col-md-12 col-12 bottom">
                        <div class="white">
                            <div class="title_white">课程列表</div>
                            <div class="chapter">
                                <!-- <p>1.高三数学知识</p> -->
                                <ul class="chapter_flex">
                                    <li
                                        class="chapter_list"
                                        :class="now_id == v.id ? 'red' : ''"
                                        v-for="(v, i) in data.list"
                                        @click="play(v, i)"
                                        :key="v.id"
                                    >
                                        {{ v.title }}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-9 col-md-12 col-12 bottom">
                        <video
                            ref="myVideo"
                            id="myVideo2"
                            controls
                            :src="
                                value == '清晰'
                                    ? v.video_file_text
                                    : v.video_file_sd_text
                            "
                            class="video-box"
                            style="width: 100%; height: 50vh"
                            v-for="(v, i) in data.list"
                            :key="i"
                            @timeupdate="onPlayerTimeupdate($event, v.id)"
                            @play="onPlayerPlay($event)"
                            controlsList="nodownload"
                            v-show="now_id == v.id"
                            oncontextmenu="return false"
                            @contextmenu="handleMouse"
                        ></video>
                        <div
                            style="
                                color: black;
                                text-align: right;
                                background-color: white;
                                padding: 5px;
                                border-radius: 5px;
                            "
                            v-if="data.comments && data.comments.length > 2"
                        >
                            切换视频清晰度:
                            <el-select
                                v-model="value"
                                placeholder="请选择"
                                @change="change_"
                            >
                                <el-option
                                    v-for="item in options"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value"
                                >
                                </el-option>
                            </el-select>
                        </div>
                    </div>
                </div>
                <div class="row" style="">
                    <div class="col-lg-12 col-md-12 col-12 bottom">
                        <div class="white_">
                            <div style="display: flex; align-items: center">
                                <img
                                    src="../assets/images/book.png"
                                    alt=""
                                    style="width: 80px; margin-right: 20px"
                                    height="80px"
                                />
                                <div class="video">
                                    <p class="video_title">{{ data.name }}</p>
                                    <p class="video_teacher">
                                        {{ data.teacher }}
                                    </p>
                                    <!-- <p
                                        class="video_teacher"
                                        v-if="data.price == '0.00'"
                                    >
                                        价格：免费
                                    </p>
                                    <p class="video_teacher" v-else>
                                        价格：{{ data.price }}
                                    </p> -->
                                </div>
                            </div>
                            <!-- <div class="free" v-if="data.price == '0.00'">
                                免费
                            </div>
                            <div class="free" v-else>{{ data.price }}</div> -->
                        </div>
                    </div>
                </div>
                <div
                    class="row"
                    style=""
                    v-if="data.list && data.list.length > 0"
                >
                    <div class="col-lg-12 col-md-12 col-12 bottom white_card">
                        <div class="tab">
                            <p>课程介绍</p>
                            <p @click="load">课程配套资料</p>
                        </div>
                        <div
                            style="padding: 10px 0"
                            v-html="data.list[now_index].description"
                        ></div>
                    </div>
                </div>

                <div class="row" style="" v-if="data.comments">
                    <div class="col-lg-12 col-md-12 col-12 bottom">
                        <div class="White" style="padding: 10px 20px">
                            <p class="list_title">评论列表</p>
                            <div
                                class="comment_flex"
                                v-for="(item, index) in data.comments"
                                :key="item.id"
                            >
                                <img
                                    src="../assets/images/account.png"
                                    alt=""
                                />
                                <div>
                                    <p>admin</p>
                                    <p>{{ item.content }}</p>
                                </div>
                            </div>
                            <div class="list_title none">发表评论</div>
                            <textarea
                                style="
                                    resize: none;
                                    background-color: rgba(238, 238, 238, 1);
                                    color: black;
                                    height: 20vh;
                                "
                                v-model="comment"
                            ></textarea>
                            <div class="add" @click="add">发布</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="line"></div>
    </Layout>
</template>

<script>
// video_file_sd_text 流畅
import Layout from "../components/common/Layout";
export default {
    name: "video",
    components: {
        Layout,
    },
    data() {
        return {
            id: "",
            model_id: "",
            page: 1,
            hot_videos: [],
            hot_articles: [],
            region: [],
            list_: [],
            categories: [],
            comment: "",
            data: [],
            now_id: "",
            channel_id: "",
            timer: null,
            currentTime_: 0,
            vedio_id: "",
            re_currentTime: "",
            options: [
                {
                    value: "清晰",
                    label: "清晰",
                },
                {
                    value: "流畅",
                    label: "流畅",
                },
            ],
            value: "清晰",
            isfirst: true,
            now_index: 0,
        };
    },
    created() {
        if (this.$route.query.id) {
            this.now_id = this.$route.query.id;
            this.channel_id = this.$route.query.channel_id;
        } else {
            this.channel_id = this.$route.query.channel_id;
        }
        this.list();
        // 设置定时器，3s执行一次
        this.timer = setInterval(() => {
            this.getTime();
        }, 3000);
    },
    beforeDestroy() {
        //清除定时器
        clearInterval(this.timer);
        this.timer = null;
    },
    watch: {},
    methods: {
        // 禁止右键点击
        handleMouse(e) {
            e.preventDefault();
        },
        load() {
            console.log(this.data.file_text);
            window.open(this.data.file_text);
        },
        change_(e) {
            this.value = e;
            console.log(e);
        },
        // 视频播放监听赋值
        onPlayerPlay(e) {
            // 第一次进来播放
            if (this.isfirst == true) {
                this.isfirst = false;
                var myVideo = document.getElementsByTagName("video")[0];
                myVideo.currentTime = this.re_currentTime;
                myVideo.play();
            }
        },
        onPlayerTimeupdate(e, id) {
            this.vedio_id = id;
            var myvideo = document.getElementsByTagName("video")[0];
            this.currentTime_ = myvideo.currentTime;
        },
        getTime() {
            this.$http({
                method: "post",
                url: "https://cyfyjiaoxue.cn//api/index/savaLog",
                headers: {
                    token: window.localStorage.getItem("token"),
                    "Content-Type": "application/json",
                },
                params: {
                    id: this.vedio_id,
                    seconds: this.currentTime_,
                },
            }).then((res) => {
                // console.log(res.body.code)
                // this.$alert(res.body.msg, '提示', {
                // 	confirmButtonText: '确定',
                // 	callback: action => {
                // 		location.reload()
                // 	}
                // });
            });
        },
        add() {
            if (this.comment == "") {
                this.$message.error("请填写留言内容！");
                return false;
            }
            console.log(this.channel_id);
            console.log(this.comment);
            this.$http({
                method: "post",
                url: "https://cyfyjiaoxue.cn//api/index/addComment",
                headers: {
                    token: window.localStorage.getItem("token"),
                    "Content-Type": "application/json",
                },
                params: {
                    channel_id: this.channel_id,
                    content: this.comment,
                },
            }).then((res) => {
                console.log(res.body.code);
                this.$alert(res.body.msg, "提示", {
                    confirmButtonText: "确定",
                    callback: (action) => {
                        location.reload();
                    },
                });
            });
            // this.$http({
            // 	method: 'post',
            // 	url: 'https://cyfyjiaoxue.cn//api/index/addComment',
            // 	params: {
            // 		channel_id: this.channel_id,
            // 		content: this.comment
            // 	},
            // 	headers: {
            // 		'token': window.localStorage.getItem('token'),
            // 	},
            // }).then(({
            // 	data
            // }) => {
            // 	console.log(data)
            // 	this.$alert(data.body.msg, '提示', {
            // 		confirmButtonText: '确定',
            // 		callback: action => {
            // 			location.reload()
            // 		}
            // 	});
            // }).catch((res) => {
            // 	this.$alert(res.body.msg, '提示', {
            // 		confirmButtonText: '确定',
            // 		callback: action => {
            // 			this.$router.replace('/login') //跳转的页面
            // 		}
            // 	});
            // });
        },
        play(item, index) {
            this.now_id = item.id;
            this.now_index = index;
            console.log(this.now_index);
        },
        detail(item) {
            console.log(item);
            const { href } = this.$router.resolve({
                path: "/videoDeatil",
                query: {
                    id: item.id,
                },
            });
            window.open(href);
            //关闭窗口的方法
            window.opener = null;
            window.open("", "_self", "");
            window.close();
        },
        // 列表
        list() {
            let this_ = this;
            this.$http({
                method: "get",
                url: "https://cyfyjiaoxue.cn//api/index/videosDetail",
                params: {
                    id: this.channel_id,
                },
                headers: {
                    token: window.localStorage.getItem("token"),
                },
            })
                .then(({ data }) => {
                    this.data = data.data;
                    // if (this.now_id == '') {
                    // 	this.now_id = this.data.list[0].id;
                    // 	console.log(this.now_id)
                    // }
                    if (data.data.now_id !== "") {
                        this.now_id = data.data.now_id;
                        let list = data.data.list;
                        list.forEach(function (item, index) {
                            if (item.id == data.data.now_id) {
                                this_.re_currentTime = item.seconds;
                            }
                        });
                    } else {
                        this.now_id = this.data.list[0].id;
                    }
                })
                .catch((res) => {
                    this.$alert(res.body.msg, "提示", {
                        confirmButtonText: "确定",
                        callback: (action) => {
                            this.$router.replace("/login"); //跳转的页面
                        },
                    });
                });
        },
    },
};
</script>
<style scoped>
.White {
    background-color: white;
    border-radius: 10px;
    padding: 10px;
}

.el-message-box__message p {
    color: black !important;
}

p {
    color: black !important;
    margin: 0;
}

.white_ {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    color: black;
    align-items: center;
}

.white {
    background-color: white;
    border-radius: 10px;
    height: 50vh;
    overflow-y: scroll;
}

.carding {
    background-color: white;
    border: none;
    border-radius: 10px;
}

.card_title {
    display: flex;
    justify-content: space-between;
    align-content: center;
    border-bottom: 1px solid rgba(227, 227, 227, 1);
    padding: 10px;
    color: rgba(10, 10, 10, 1);
    margin: 0 10px;
}

.card_content {
    padding: 10px 15px;
    border-bottom: 1px solid rgba(227, 227, 227, 1);
    display: flex;
    justify-content: space-between;
}

.card_content p.title {
    color: rgba(23, 109, 194, 1) !important;
    margin: 0;
    width: 70%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.card_content p.date {
    color: rgba(145, 141, 141, 1) !important;
    margin: 0;
}

.bottom {
    margin-bottom: 15px;
}

.line {
    width: 100%;
    height: 5vh;
}

.title {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.title p {
    margin: 0;
}

.title .title_ {
    font-size: 36px;
    font-weight: 500;
    letter-spacing: 0px;
    color: rgba(0, 0, 0, 1);
    text-align: left;
    vertical-align: top;
}

.title .lookmore {
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0px;
    color: rgba(107, 107, 107, 1);
    text-align: left;
    vertical-align: top;
    border: none;
}

.cardWhite {
    background-color: white;
}

.cardTitle p {
    margin: 0;
}

.cardTitle {
    padding: 10px;
}

.cardTitle .title {
    font-size: 16px;
}

.cardTitle .desc {
    font-size: 12px;
    color: rgba(87, 87, 87, 1);
}

.title_white {
    color: rgba(0, 0, 0, 1);
    padding: 15px;
}

.chapter {
    margin-bottom: 30px;
}

.chapter p {
    color: black;
    border-bottom: 1px solid rgba(235, 235, 235, 1);
    padding: 10px;
    margin-bottom: 0;
}

.chapter_flex {
    padding: 0%;
}

.chapter_list {
    padding: 5px 20px;
    color: black;
    list-style: none;
    cursor: pointer;
    font-size: 16px;
}

.video {
    color: black;
}

.video_title {
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0px;
    color: rgba(0, 0, 0, 1);
    text-align: left;
    vertical-align: top;
}

.video_teacher {
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0px;
    color: rgba(82, 82, 82, 1);
    text-align: left;
    vertical-align: top;
}

.free {
    border-radius: 10px;
    background: rgba(97, 171, 255, 1);
    color: white;
    width: 100px;
    text-align: center;
    height: 50px;
    line-height: 50px;
}

.list_title {
    border-bottom: 1px solid rgba(207, 207, 207, 1);
    padding: 10px;
    margin-bottom: 20px;
}

.comment_flex {
    display: flex;
    padding: 20px 0;
    border-bottom: 1px solid rgba(207, 207, 207, 1);
}

.comment_flex img {
    margin-right: 20px;
    width: 50px;
    height: 50px;
}

.none {
    border: none !important;
    color: black;
    margin: 0;
}

.add {
    background-color: rgba(97, 171, 255, 1);
    color: white;
    text-align: center;
    border-radius: 10px;
    text-align: center;
    padding: 9px;
    margin: 20px auto;
}

.red {
    color: red;
}

/deep/.el-select .el-input__inner {
    cursor: pointer;
    padding-right: 35px;
    color: black !important;
    border: 1px solid #c0c4cc;
}

/deep/.el-input__prefix,
.el-input__suffix {
    top: 5px !important;
}

/deep/.el-input__icon {
    line-height: 30px !important;
}

.tab {
    width: 100%;
    border-bottom: 1px solid rgba(224, 224, 224, 1);
    padding-bottom: 15px;
    display: flex;
}

.tab p {
    margin-right: 4vw;
    cursor: pointer;
}

.white_card {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    color: black;
}
</style>
