<template>
    <Layout header-class="" :show-newsletter="true" :show-cta="false">
        <el-carousel :interval="5000">
            <el-carousel-item v-for="(item, index) in banner" :key="index">
                <div @click="banner_detail(item)">
                    <img
                        :src="item.image"
                        alt
                        style="width: 100%; height: auto; position: relative"
                    />
                    <p class="swiper">{{ item.title }}</p>
                </div>
            </el-carousel-item>
        </el-carousel>

        <div class="rn-demo-area" id="demo">
            <div class="container">
                <div class="row" style="padding: 30px 0">
                    <div class="col-lg-12 col-md-12 col-12 white">
                        <p
                            v-for="(item, index) in region"
                            style="color: black"
                            @click="change(item)"
                            :key="item.id"
                        >
                            {{ item.name }}
                        </p>
                    </div>
                </div>
                <div class="row" style="">
                    <div
                        class="col-lg-6 col-md-12 col-12 bottom"
                        v-for="(item, index) in categories"
                        :key="item.id"
                    >
                        <div class="carding">
                            <div class="card_title">
                                <div>{{ item.name }}</div>
                                <div></div>
                            </div>
                            <div class="" style="padding: 0px 15px 10px">
                                <div
                                    class="card_content"
                                    v-for="(v, i) in item.list"
                                    @click="godetail(v.id)"
                                    :key="v.id"
                                >
                                    <p class="title">
                                        <img
                                            src="../assets/images/list_icon.png"
                                            alt=""
                                            style="
                                                width: 16px;
                                                margin-right: 10px;
                                            "
                                        />
                                        {{ v.title }}
                                    </p>
                                    <p class="date">{{ v.createtime_text }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>

<script>
import Layout from "../components/common/Layout";
import Separator from "../components/elements/separator/Separator";
import SectionTitle from "../components/elements/sectionTitle/SectionTitle";
import AboutFour from "../components/elements/about/AboutFour";
import ServiceOne from "../components/elements/service/ServiceOne";
import Timeline from "../components/elements/timeline/Timeline";
import Counter from "../components/elements/counterUp/Counter";
import Brand from "../components/elements/brand/Brand";
import Team from "../components/elements/team/Team";

export default {
    name: "About",
    components: {
        Team,
        Brand,
        Counter,
        Timeline,
        ServiceOne,
        AboutFour,
        SectionTitle,
        Separator,
        Layout,
    },
    data() {
        return {
            id: "",
            model_id: "",
            page: 1,
            hot_videos: [],
            hot_articles: [],
            region: [],
            list_: [],
            categories: [],
            region_id: "",
            banner: [],
        };
    },
    created() {
        this.id = this.$route.query.id;
        this.model_id = this.$route.query.model_id;
        this.list();
        this.bannerShow();
    },
    methods: {
        banner_detail(item) {
            this.$router.push({
                path: `/detail?id=${item.id}`,
            });
        },
        bannerShow() {
            this.$http
                .get(
                    "https://cyfyjiaoxue.cn//api/index/getBannerList",
                    {
                        params: {
                            source_channel_id: this.id,
                        },
                    },
                    {
                        emulateJSON: true,
                    }
                )
                .then(
                    function (res) {
                        if (res.data.code == 200) {
                            this.banner = res.data.data;
                            console.log(this.banner, "============");
                        } else if (res.data.code == 114) {
                            this.$alert(res.data.msg, "提示", {
                                confirmButtonText: "确定",
                                callback: (action) => {
                                    this.$router.push({
                                        path: `/login`,
                                    });
                                },
                            });
                        }
                    },
                    function (res) {
                        console.log(res); // 响应错误回调
                    }
                );
        },
        change(item) {
            console.log(item);
            this.region_id = item.id;
            this.list();
        },
        godetail(id) {
            this.$router.push({
                path: `/detail?id=${id}`,
            });
        },
        // 列表
        list() {
            this.$http
                .get(
                    "https://cyfyjiaoxue.cn//api/index/navJump",
                    {
                        params: {
                            id: this.id,
                            model_id: this.model_id,
                            page: this.page,
                            row: 10,
                            region_id: this.region_id,
                        },
                    },
                    {
                        emulateJSON: true,
                    }
                )
                .then(
                    function (res) {
                        this.region = res.data.data.region;
                        this.categories = res.data.data.categories;
                    },
                    function (res) {
                        console.log(res); // 响应错误回调
                    }
                );
        },
    },
};
</script>
<style scoped>
.white {
    background-color: white;
    padding: 10px 0;
    border-radius: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
}

.white p {
    padding: 5px 10px;
    width: auto;
    border: 1px solid rgba(179, 171, 171, 1);
    border-radius: 5px;
    text-align: center;
    margin: 0 10px;
    margin-bottom: 15px;
}

.carding {
    background-color: white;
    border: none;
    border-radius: 10px;
}

.card_title {
    display: flex;
    justify-content: space-between;
    align-content: center;
    border-bottom: 1px solid rgba(227, 227, 227, 1);
    padding: 10px;
    color: rgba(10, 10, 10, 1);
    margin: 0 10px;
}

.card_content {
    padding: 10px 15px;
    border-bottom: 1px solid rgba(227, 227, 227, 1);
    display: flex;
    justify-content: space-between;
}

.card_content p.title {
    color: rgba(23, 109, 194, 1) !important;
    margin: 0;
    width: 70%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: flex;
    align-items: center;
}

.card_content p.date {
    color: rgba(145, 141, 141, 1) !important;
    margin: 0;
}

.bottom {
    margin-bottom: 15px;
}
</style>
