<template>
    <div style="width: 100%">
        <ul class="mainmenu">
            <li
                class="has-droupdown has-menu-child-item none"
                style="width: 8.3%; text-align: center"
            >
                <a href="/#/"> 首页 </a>
            </li>
            <li
                class="has-droupdown has-menu-child-item"
                v-for="(item, index) in nav"
                :key="item.id"
                @click="change(item)"
            >
                <a
                    href="#"
                    v-if="item.sub.length > 0"
                    style="pointer-events: none"
                >
                    {{ item.name }}
                    <Icon name="plus" class="collapse-icon plus" />
                    <Icon name="minus" class="collapse-icon minus" />
                </a>
                <a href="#" v-else @click.stop="detail(item)">
                    {{ item.name }}
                    <Icon name="plus" class="collapse-icon plus" />
                    <Icon name="minus" class="collapse-icon minus" />
                </a>
                <ul class="submenu" v-show="item.sub.length > 0">
                    <p
                        v-for="(v, i) in item.sub"
                        @click.stop="detail(v)"
                        :key="v.id"
                        style="text-align: center; padding: 5px; color: #ffffff"
                    >
                        {{ v.name }}
                    </p>
                </ul>
            </li>
        </ul>
    </div>
</template>

<script>
import Icon from "../../icon/Icon";
export default {
    name: "Nav",
    components: {
        Icon,
    },
    data() {
        return {
            nav: [],
        };
    },
    created() {
        this.getData();
        console.log("nav----------------");
    },
    methods: {
        // 首页信息
        getData() {
            this.$http.get("https://cyfyjiaoxue.cn//api/index/index").then(
                function (res) {
                    this.nav = res.body.data.nav;
                },
                function (res) {
                    console.log(res); // 响应错误回调
                }
            );
        },
        change(item) {
            let model = item.sub[0];
            if (model.model_id == 1) {
                const { href } = this.$router.resolve({
                    path: "/about",
                    query: {
                        id: model.id,
                        model_id: model.model_id,
                    },
                });
                window.open(href);
                // //关闭窗口的方法
                // window.opener = null;
                // window.open("", "_self", "");
                // window.close();
            } else if (model.model_id == 3) {
                if (model.name == "商品橱窗") {
                    const { href } = this.$router.resolve({
                        path: "/shop",
                        query: {
                            id: model.id,
                            model_id: model.model_id,
                        },
                    });
                    window.open(href);
                    // //关闭窗口的方法
                    // window.opener = null;
                    // window.open("", "_self", "");
                    // window.close();
                } else {
                    const { href } = this.$router.resolve({
                        path: "/zldetail",
                        query: {
                            id: model.id,
                            model_id: model.model_id,
                        },
                    });
                    window.open(href);
                    // //关闭窗口的方法
                    // window.opener = null;
                    // window.open("", "_self", "");
                    // window.close();
                }
            } else if (model.model_id == 2) {
                const { href } = this.$router.resolve({
                    path: "/video",
                    query: {
                        id: model.id,
                        model_id: model.model_id,
                    },
                });
                window.open(href);
                // //关闭窗口的方法
                // window.opener = null;
                // window.open("", "_self", "");
                // window.close();
            }
        },
        detail(item) {
            if (item.model_id == 1) {
                const { href } = this.$router.resolve({
                    path: "/about",
                    query: {
                        id: item.id,
                        model_id: item.model_id,
                    },
                });
                window.open(href);
                // //关闭窗口的方法
                // window.opener = null;
                // window.open("", "_self", "");
                // window.close();
            } else if (item.model_id == 3) {
                if (item.name == "商品橱窗") {
                    const { href } = this.$router.resolve({
                        path: "/shop",
                        query: {
                            id: item.id,
                            model_id: item.model_id,
                        },
                    });
                    window.open(href);
                    // //关闭窗口的方法
                    // window.opener = null;
                    // window.open("", "_self", "");
                    // window.close();
                } else {
                    const { href } = this.$router.resolve({
                        path: "/zldetail",
                        query: {
                            id: item.id,
                            model_id: item.model_id,
                        },
                    });
                    window.open(href);
                    // //关闭窗口的方法
                    // window.opener = null;
                    // window.open("", "_self", "");
                    // window.close();
                }
            } else if (item.model_id == 2) {
                const { href } = this.$router.resolve({
                    path: "/video",
                    query: {
                        id: item.id,
                        model_id: item.model_id,
                    },
                });
                window.open(href);
                // //关闭窗口的方法
                // window.opener = null;
                // window.open("", "_self", "");
                // window.close();
            }
        },
    },
};
</script>
<style>
.mainmenu-nav .mainmenu li {
    cursor: pointer;
}

.mainmenu-nav .mainmenu > li > a {
    color: #ffffff;
    font-size: 16px;
    font-weight: 600 !important;
    text-align: center;
    width: 110px;
}

.has-menu-child-item {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    width: 8.3%;
}

.has-menu-child-item::before {
    content: "|";
    box-sizing: border-box;
    display: flex;
    align-items: center;
    color: rgba(204, 204, 204, 1);
}

.none::before {
    content: "";
}

@media screen and (max-width: 768px) {
    .has-menu-child-item::before {
        content: "";
        box-sizing: border-box;
        display: flex;
        align-items: center;
        color: rgba(204, 204, 204, 1);
    }

    .has-menu-child-item {
        display: flex;
        align-items: center;
        box-sizing: border-box;
        width: 100%;
    }
    .popup-mobile-menu .mainmenu .has-droupdown .submenu.active {
        height: 100%;
        opacity: 1;
        visibility: visible;
        padding-bottom: 12px;
    }
    .popup-mobile-menu .mainmenu .has-droupdown .submenu {
        height: 0;
        transition: all 0.3s;
        opacity: 0;
        visibility: hidden;
        padding: 0;
        max-width: 100%;
        list-style: none;
        padding-left: 14px;
    }
}

/* >=992 and  */
@media screen and (min-width: 992px) {
}

/* >=768px and <1200px */
@media screen and (min-width: 768px) and (max-width: 1200px) {
}

/* >=1200px */
@media screen and (min-width: 1200px) {
}
</style>
