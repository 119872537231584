<template>
	<div class="footer-style-2">
		<section class="container">
			<div class="row">
				<div class="footer_ row" v-if="data1.config">
					<div class="col-lg-9 col-md-12 col-12">
						<div style="border-bottom: 1px solid white;padding-bottom: 10px;">
							<p style="margin: 0;">咨询电话</p>
							<p style="margin: 0;">{{data1.config.mobile}}</p>
						</div>
						<div style="padding: 15px 0;">{{data1.config.state}}   {{data1.config.beian}}</div>
					</div>
					<div class="col-lg-3 col-md-12 col-12">
						<img :src="data1.config.qrcode" alt="" style="width: 150px;height: 150px;">
					</div>
				</div>
			</div>
		</section>
	</div>
</template>

<script>
	import CopyrightTwo from './CopyrightTwo'
	import CallToAction from '../../elements/callToAction/CallToAction'
	import ScrollTop from './ScrollTop'

	export default {
		name: 'FooterTwo',
		components: {
			CallToAction,
			CopyrightTwo,
			ScrollTop
		},
		props: {
			data: {
				default: null
			}
		},
		data() {
			return {
				data1:{}
			}
		},
		created() {
			this.getData()
		},
		methods:{
			// 首页信息
			getData() {
				this.$http.get('https://cyfyjiaoxue.cn//api/index/index').then(function(res) {
					this.data1 = res.body.data;
				}, function(res) {
					console.log(res) // 响应错误回调
				})
			},
		}
	}
</script>
<style>
	.footer-style-2 {
		background-color: rgba(97, 171, 255, 1);
	}

	.footer_ {
		color: white;
		padding: 20px 0;
	}
</style>
