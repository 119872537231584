<template>
    <Layout header-class="" :show-newsletter="true" :show-cta="false">
        <div class="rn-demo-area" id="demo">
            <div class="container">
                <div class="row" style="padding: 30px 0">
                    <div class="col-lg-12 col-md-12 col-12">
                        <div class="about_list">
                            >>> 搜索 <span class="red">{{ key }}</span> 有{{
                                list_.length
                            }}条结果
                        </div>
                        <div class="about_line"></div>
                        <section
                            class="newCard"
                            v-for="(item, index) in list_"
                            @click="detail(item)"
                            :key="item.id"
                        >
                            <div class="newCard_title">{{ item.title }}</div>
                            <p class="newCard_tip">
                                {{ item.description }}
                            </p>
                            <div class="newCard_flex">
                                <!-- <p>热门资讯</p> -->
                                <p>{{ item.createtime_text }}</p>
                            </div>
                        </section>
                        <!-- 分页 -->
                        <el-pagination
                            background
                            layout="pager, next"
                            :total="list_.length"
                            :page-sizes="[10, 20, 30, 40, 50]"
                            @current-change="changenum"
                        >
                        </el-pagination>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>

<script>
import Layout from "../components/common/Layout";
import Separator from "../components/elements/separator/Separator";
import SectionTitle from "../components/elements/sectionTitle/SectionTitle";
import AboutFour from "../components/elements/about/AboutFour";
import ServiceOne from "../components/elements/service/ServiceOne";
import Timeline from "../components/elements/timeline/Timeline";
import Counter from "../components/elements/counterUp/Counter";
import Brand from "../components/elements/brand/Brand";
import Team from "../components/elements/team/Team";

export default {
    name: "About",
    components: {
        Team,
        Brand,
        Counter,
        Timeline,
        ServiceOne,
        AboutFour,
        SectionTitle,
        Separator,
        Layout,
    },
    data() {
        return {
            id: "",
            model_id: "",
            page: 1,
            list_: [],
        };
    },
    onLoad(option) {
        console.log(option);
    },
    created() {
        this.key = this.$route.query.seachKey;
        this.show();
    },
    methods: {
        detail_(item) {
            this.$router.push({
                path: `/videoDeatil?channel_id=${item.id}`,
            });
        },
        // 列表详情点击
        detail(item) {
            console.log(item);
            this.$router.push({
                path: `/detail?id=${item.id}`,
            });
        },
        // 分页点击
        changenum(e) {
            this.page = e;
            this.show();
        },
        //
        show() {
            this.$http({
                method: "get",
                url: "https://cyfyjiaoxue.cn//api/index/search",
                params: {
                    keyword: this.key,
                    page: this.page,
                    row: 10,
                },
                headers: {
                    token: window.localStorage.getItem("token"),
                },
            })
                .then(({ data }) => {
                    console.log(data, "1111111111111");
                    this.list_ = data.data;
                })
                .catch((res) => {
                    console.log(res, "-------------------");
                    // this.$alert(res.body.msg, '提示', {
                    // 	confirmButtonText: '确定',
                    // 	callback: action => {
                    // 		const {
                    // 			href
                    // 		} = this.$router.resolve({
                    // 			path: '/login',
                    // 		});
                    // 		window.open(href);
                    // 		//关闭窗口的方法
                    // 		window.opener = null
                    // 		window.open('', '_self', '')
                    // 		window.close()
                    // 	}
                    // });
                });
        },
    },
};
</script>
<style scoped>
.about_list {
    display: flex;
    width: 100%;
    color: black;
}

.about_list p {
    font-size: 20.47px;
    font-weight: 400;
    letter-spacing: 0px;
    color: rgba(112, 112, 112, 100);
    text-align: left;
    vertical-align: top;
    margin: 0;
    width: 14%;
    line-height: 60px;
    text-align: center;
}

.about_line {
    width: 100%;
    height: 10px;
    opacity: 1;
    background: rgba(97, 171, 255, 1);
    color: rgba(97, 171, 255, 1);
    border-radius: 5px;
}

.active {
    color: #000 !important;
    display: flex;
    align-items: center;
}

.active::before {
    content: " ";
    width: 12px;
    height: 12px;
    opacity: 1;
    background: rgba(0, 0, 0, 1);
    color: #000;
    border-radius: 50%;
    margin-right: 5px;
    display: block;
}

.newCard {
    border-bottom: 0.85px solid rgba(166, 166, 166, 1);
    padding: 20px 0;
    cursor: pointer;
}

.newCard_title {
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0px;
    color: rgba(0, 0, 0, 1);
    text-align: justify;
    vertical-align: top;
    line-height: 50px;
}

.newCard_tip {
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0px;
    color: rgba(64, 64, 64, 1);
    text-align: justify;
}

.newCard_flex {
    display: flex;
}

.newCard_flex p {
    color: #000;
    margin: 0;
}

.hot {
    opacity: 1;
    background: rgba(97, 171, 255, 1);
    color: #fff;
    font-size: 20.47px;
    font-weight: 400;
    letter-spacing: 0px;
    color: rgba(255, 255, 255, 100);
    text-align: left;
    vertical-align: top;
    padding: 15px 20px;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    position: absolute;
    width: 100%;
    right: 0;
}

.hotCard {
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    background-color: white;
    position: relative;
    /* padding: 0 3% !important; */
}

.hot_ {
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 1.71px 3.41px 0px rgba(0, 0, 0, 0.25);
    margin-bottom: 20px;
    margin: 20px 5%;
}

.hot_ p {
    margin: 0%;
    color: #000;
    padding: 10px;
}

.hot_tip {
    text-align: right;
    font-size: 13.65px;
    font-weight: 400;
    letter-spacing: 0px;
    color: rgba(87, 87, 87, 1);
    text-align: right;
    vertical-align: top;
}

.hot_article {
    width: 100%;
    background: rgba(97, 171, 255, 1);
    position: absolute;
    left: 0;
    padding: 15px;
}

.articleList {
    color: #000;
    padding: 10px;
    display: flex;
    align-items: center;
}

.articleList .cirle {
    width: 25px;
    height: 25px;
    opacity: 1;
    background: rgba(97, 171, 255, 1);
    border-radius: 50%;
    color: white !important;
    text-align: center;
    margin: 0;
    margin-right: 15px;
}

.active::before {
    content: " ";
    width: 0px;
    height: 0px;
    opacity: 1;
    background: rgba(0, 0, 0, 1);
    color: #000;
    border-radius: 50%;
    margin-right: 5px;
    display: block;
}

.el-pager li {
    margin: 0 5px;
    background-color: rgba(161, 157, 157, 1);
    color: #606266;
    min-width: 30px;
    border-radius: 2px;
}

.el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: rgba(97, 171, 255, 1) !important;
    color: #fff !important;
    width: 30px;
    height: 30px;
    border-radius: 5px;
}

.el-pagination.is-background .el-pager li {
    margin: 0 5px;
    background-color: rgba(161, 157, 157, 1) !important;
    color: #fff !important;
    min-width: 50px !important;
    min-height: 50px;
    border-radius: 5px !important;
    font-size: 16px;
    line-height: 50px;
}

.el-pagination .btn-next .el-icon,
.el-pagination .btn-prev .el-icon {
    display: block;
    font-size: 20px !important;
    font-weight: 700;
    line-height: 50px;
}

.el-pagination {
    margin: 20px 0;
}
</style>
