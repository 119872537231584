<template>
    <Layout header-class="" :show-newsletter="true" :show-cta="false">
        <div class="rn-demo-area" id="demo">
            <div class="container">
                <div class="row" style="padding: 30px 0">
                    <div
                        class="col-lg-3 col-md-12 col-12"
                        style="
                            padding: 30px 0;
                            background-color: white;
                            text-align: center;
                            height: 60vh;
                        "
                    >
                        <img
                            src="../assets/images/mine_icon.png"
                            alt=""
                            height="80px"
                            width="80px"
                            style="
                                margin-bottom: 20px;
                                margin: 0 auto;
                                width: 80px;
                                height: 80px;
                            "
                        />
                        <div
                            class="flex"
                            v-for="(item, index) in List"
                            @click="change(index)"
                            :class="list_count == index ? 'red' : ''"
                            :key="item.id"
                        >
                            <img
                                src="@/assets/images/one.png"
                                alt=""
                                width="20px"
                                height="20px"
                                style="margin-right: 5px"
                                v-show="index == 0"
                            />
                            <img
                                src="@/assets/images/two.png"
                                alt=""
                                width="20px"
                                height="20px"
                                style="margin-right: 5px"
                                v-show="index == 1"
                            />
                            <img
                                src="@/assets/images/three.png"
                                alt=""
                                width="20px"
                                height="20px"
                                style="margin-right: 5px"
                                v-show="index == 2"
                            />
                            <img
                                src="@/assets/images/four.png"
                                alt=""
                                width="20px"
                                height="20px"
                                style="margin-right: 5px"
                                v-show="index == 3"
                            />
                            <img
                                src="@/assets/images/five.png"
                                alt=""
                                width="20px"
                                height="20px"
                                style="margin-right: 5px"
                                v-show="index == 4"
                            />
                            {{ item.title }}
                        </div>
                    </div>
                    <div
                        class="col-lg-9 col-md-12 col-12 bottom"
                        style="height: 60vh"
                    >
                        <div class="carding padding_" v-show="list_count == 0">
                            <div class="title">全部课程</div>
                            <div class="inner-containe">
                                <div
                                    class="card"
                                    v-for="(item, index) in mine_lesson.data"
                                    @click="detail_(item)"
                                    :key="item.id"
                                >
                                    <img
                                        :src="item.image"
                                        alt=""
                                        style="width: 100%; height: 140px"
                                    />
                                    <div class="cardTitle">
                                        <div>{{ item.name }}）</div>
                                        <div class="right">
                                            {{ item.buy_num }}次学习
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- 分页 -->
                            <el-pagination
                                background
                                layout="pager, next"
                                :total="mine_lesson.total"
                                :page-count="mine_lesson.current_page"
                                :page-sizes="[10, 20, 30, 40, 50]"
                                @current-change="changenum"
                            >
                            </el-pagination>
                        </div>
                        <div
                            class="carding white form"
                            v-show="list_count == 1"
                        >
                            <div class="form_line">账号：{{ username }}</div>
                            <div class="form_line">
                                密码：<input
                                    type="password"
                                    placeholder="请输入新密码"
                                    style="width: 70%"
                                    v-model="password"
                                />
                            </div>
                            <div class="edit" @click="edit()">修改</div>
                        </div>
                        <div class="carding padding_" v-show="list_count == 2">
                            <div class="status_mine">
                                <p
                                    class=""
                                    v-for="(item, index) in status_tab"
                                    :key="item.id"
                                    :class="
                                        status_index == index ? 'chiose' : ''
                                    "
                                    @click="change_status(index, item.status)"
                                >
                                    {{ item.name }}
                                </p>
                            </div>
                            <div class="form_head row">
                                <p class="col-lg-6 col-md-12 col-12">商品</p>
                                <p class="col-lg-2 col-md-12 col-12">
                                    快递单号
                                </p>
                                <p class="col-lg-2 col-md-12 col-12">件数</p>
                                <p class="col-lg-2 col-md-12 col-12">
                                    售价(元)
                                </p>
                            </div>
                            <div
                                class="form_content row"
                                v-for="(item, index) in order_message.data"
                                :key="item.id"
                                @click="pay(item)"
                            >
                                <div class="form_img col-lg-6 col-md-12 col-12">
                                    <img
                                        :src="item.goods_image"
                                        alt=""
                                        width="135px"
                                        style="margin-right: 5px"
                                    />
                                    <div>
                                        <section class="book_title">
                                            {{ item.goods_name }}
                                        </section>
                                        <p class="tip">
                                            {{ item.goods_introduction }}
                                        </p>
                                    </div>
                                </div>
                                <div class="width_ col-lg-2 col-md-12 col-12">
                                    {{ item.tracking_number }}
                                </div>
                                <div class="width_ col-lg-2 col-md-12 col-12">
                                    {{ item.goods_num }}
                                </div>
                                <div
                                    class="width_ col-lg-2 col-md-12 col-12 money"
                                >
                                    {{ item.amount }}
                                </div>
                            </div>
                            <!-- 分页 -->
                            <el-pagination
                                background
                                layout="pager, next"
                                :total="order_message.total"
                                :page-count="order_message.current_page"
                                :page-sizes="[10, 20, 30, 40, 50]"
                                @current-change="changenum3"
                            >
                            </el-pagination>
                        </div>

                        <div class="carding padding_" v-show="list_count == 3">
                            <div class="title">历史视频</div>

                            <div class="inner-containe">
                                <div
                                    class="card"
                                    v-for="(item, index) in hi_lesson.data"
                                    @click="detail_(item)"
                                    :key="item.id"
                                >
                                    <img
                                        :src="item.image"
                                        alt=""
                                        style="width: 100%; height: 140px"
                                    />
                                    <div class="cardTitle">
                                        <div>{{ item.name }}）</div>
                                        <div class="right">
                                            {{ item.buy_num }}次学习
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- 分页 -->
                            <el-pagination
                                background
                                layout="pager, next"
                                :total="hi_lesson.total"
                                :page-count="hi_lesson.current_page"
                                :page-sizes="[10, 20, 30, 40, 50]"
                                @current-change="changenum"
                            >
                            </el-pagination>
                        </div>

                        <div class="carding padding_" v-show="list_count == 4">
                            <div class="form_head row">
                                <p class="col-lg-6 col-md-12 col-12">商品</p>
                                <p class="col-lg-3 col-md-12 col-12">件数</p>
                                <p class="col-lg-3 col-md-12 col-12">
                                    售价(元)
                                </p>
                            </div>
                            <div
                                class="form_content row"
                                v-for="(item, index) in mine_data.data"
                                @click="content(item)"
                                :key="item.id"
                            >
                                <div class="form_img col-lg-6 col-md-12 col-12">
                                    <img
                                        :src="item.archives.image"
                                        alt=""
                                        width="135px"
                                        style="margin-right: 5px"
                                    />
                                    <div>
                                        <section class="book_title">
                                            {{ item.archives.title }}
                                        </section>
                                    </div>
                                </div>
                                <div class="width_ col-lg-3 col-md-12 col-12">
                                    1
                                </div>
                                <div
                                    class="width_ col-lg-3 col-md-12 col-12 money"
                                >
                                    {{ item.payamount }}
                                </div>
                            </div>

                            <!-- 分页 -->
                            <el-pagination
                                background
                                layout="pager, next"
                                :total="mine_data.total"
                                :page-count="mine_data.current_page"
                                :page-sizes="[10, 20, 30, 40, 50]"
                                @current-change="changenum4"
                            >
                            </el-pagination>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>

<script>
import Layout from "../components/common/Layout";
import Separator from "../components/elements/separator/Separator";
import SectionTitle from "../components/elements/sectionTitle/SectionTitle";
import AboutFour from "../components/elements/about/AboutFour";
import ServiceOne from "../components/elements/service/ServiceOne";
import Timeline from "../components/elements/timeline/Timeline";
import Counter from "../components/elements/counterUp/Counter";
import Brand from "../components/elements/brand/Brand";
import Team from "../components/elements/team/Team";

export default {
    name: "About",
    components: {
        Team,
        Brand,
        Counter,
        Timeline,
        ServiceOne,
        AboutFour,
        SectionTitle,
        Separator,
        Layout,
    },
    data() {
        return {
            id: "",
            model_id: "",
            page: 1,
            hot_videos: [],
            hot_articles: [],
            region: [],
            list_: [],
            categories: [],
            List: [
                {
                    title: "我的课程",
                    icon: "@/assets/images/one.png",
                },
                {
                    title: "个人信息",
                    icon: "@/assets/images/two.png",
                },
                // {
                // title: "订单信息",
                // icon: "@/assets/images/three.png",
                // },
                // {
                // title: "历史视频",
                // icon: "@/assets/images/four.png",
                // },
                // {
                //     title: "我的数据",
                //     icon: "@/assets/images/five.png",
                // },
            ],
            list_count: 0,
            password: "",
            username: "",
            page1: 1,
            mine_lesson: {},
            hi_lesson: {},
            page2: 1,
            status_tab: [
                {
                    status: "created",
                    name: "待支付",
                },
                {
                    status: "payed",
                    name: "已支付",
                },
            ],
            status_index: 0,
            type: "created",
            order_message: {},
            page3: 1,
            orderpage: 1,
            order_data: {},
            mine_data: {},
        };
    },
    created() {
        this.list();
        this.show();
    },
    watch: {
        list_count(val) {
            if (val == 0) {
                this.show(); //我的课程
            } else if (val == 2) {
                this.statusShow();
            } else if (val == 3) {
                this.show2();
            } else if (val == 4) {
                this.show3();
            }
        },
    },
    methods: {
        pay(item) {
            console.log(item);
            this.$router.push({
                path: `/buy?goods_id=${item.goods_id}&goods_num=${item.goods_num}`,
            });
        },
        content(item) {
            console.log(item.id);
            this.$router.push({
                path: `/detail?id=${item.id}`,
            });
        },
        change_status(index, type) {
            this.status_index = index;
            this.type = type;
            this.statusShow();
        },
        show3() {
            // 我的数据
            this.$http({
                method: "get",
                url: "https://cyfyjiaoxue.cn//api/index/order",
                headers: {
                    token: window.localStorage.getItem("token"),
                    "Content-Type": "application/json",
                },
                params: {
                    page: this.orderpage,
                    row: 10,
                },
            }).then((res) => {
                this.mine_data = JSON.parse(res.bodyText).data.orderList;
                console.log(this.mine_data);
            });
        },
        statusShow() {
            // 订单信息
            this.$http({
                method: "get",
                url: "https://cyfyjiaoxue.cn///api/goods_order/getMyOrder",
                headers: {
                    token: window.localStorage.getItem("token"),
                    "Content-Type": "application/json",
                },
                params: {
                    page: this.page3,
                    row: 10,
                    status: this.type,
                },
            }).then((res) => {
                this.order_message = res.body.data;
            });
        },
        detail_(item) {
            this.$router.push({
                path: `/videoDeatil?channel_id=${item.id}`,
            });
        },
        // 分页点击
        changenum(e) {
            this.page1 = e;
            this.show();
        },
        changenum2(e) {
            this.page2 = e;
            this.show2();
        },
        changenum3(e) {
            this.page3 = e;
            this.statusShow();
        },
        changenum4(e) {
            this.orderpage = e;
            this.show3();
        },
        show() {
            // 我的课程
            this.$http({
                method: "get",
                url: "https://cyfyjiaoxue.cn//api/index/myCourses",
                headers: {
                    token: window.localStorage.getItem("token"),
                    "Content-Type": "application/json",
                },
                params: {
                    page: this.page1,
                    row: 10,
                },
            }).then((res) => {
                this.mine_lesson = res.body.data;
            });
        },
        show2() {
            // 历史视频
            this.$http({
                method: "get",
                url: "https://cyfyjiaoxue.cn//api/index/historyCourses",
                headers: {
                    token: window.localStorage.getItem("token"),
                    "Content-Type": "application/json",
                },
                params: {
                    page: this.page2,
                    row: 10,
                },
            }).then((res) => {
                this.hi_lesson = res.body.data;
                console.log(res.body.data, "我的历史视频");
            });
        },
        change(index) {
            this.list_count = index;
        },
        edit() {
            if (this.password == "") {
                this.$message.error("请填写新密码！");
                return false;
            }
            this.$http({
                method: "post",
                url: "https://cyfyjiaoxue.cn//api/index/resetpwd",
                headers: {
                    token: window.localStorage.getItem("token"),
                    "Content-Type": "application/json",
                },
                params: {
                    newpassword: this.password,
                },
            }).then((res) => {
                this.$alert(res.body.msg, "提示", {
                    confirmButtonText: "确定",
                    callback: (action) => {
                        location.reload();
                    },
                });
            });
        },
        // 列表
        list() {
            // 用户信息
            this.$http({
                method: "post",
                url: "https://cyfyjiaoxue.cn//api/index/userInfo",
                headers: {
                    token: window.localStorage.getItem("token"),
                    "Content-Type": "application/json",
                },
            }).then((res) => {
                if (res.body.code == 200) {
                    this.username = res.body.data.username;
                } else if (res.body.code == 114) {
                    this.$alert(res.data.msg, "提示", {
                        confirmButtonText: "确定",
                        callback: (action) => {
                            this.$router.push({
                                path: `/login`,
                            });
                        },
                    });
                }
            });
        },
    },
};
</script>
<style scoped>
.flex {
    width: 100%;
    display: flex;
    align-items: center;
    color: black;
    font-size: 20px;
    justify-content: center;
    margin: 10px 0;
    cursor: pointer;
}

.white {
    background-color: white;
    padding: 10px 0;
    border-radius: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
}

.white p {
    padding: 5px 10px;
    width: auto;
    border: 1px solid rgba(179, 171, 171, 1);
    border-radius: 5px;
    text-align: center;
    margin: 0 10px;
    margin-bottom: 15px;
}

.carding {
    background-color: white;
    border: none;
    border-radius: 10px;
    height: 100%;
    overflow-y: scroll;
}

.card_title {
    display: flex;
    justify-content: space-between;
    align-content: center;
    border-bottom: 1px solid rgba(227, 227, 227, 1);
    padding: 10px;
    color: rgba(10, 10, 10, 1);
    margin: 0 10px;
}

.card_content {
    padding: 10px 15px;
    border-bottom: 1px solid rgba(227, 227, 227, 1);
    display: flex;
    justify-content: space-between;
}

.card_content p.title {
    color: rgba(23, 109, 194, 1) !important;
    margin: 0;
    width: 70%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.card_content p.date {
    color: rgba(145, 141, 141, 1) !important;
    margin: 0;
}

.bottom {
    margin-bottom: 15px;
}

.form {
    color: rgba(140, 140, 140, 1);
    padding: 10vh 0 4vh;
}

.form_line {
    border-bottom: 1px solid rgba(204, 204, 204, 1);
    width: 80%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    padding: 15px 0px;
    height: 70px;
}

.form_line input {
    color: black;
}

.edit {
    background-color: rgba(60, 140, 232, 1);
    color: white;
    padding: 10px;
    width: 40vw;
    text-align: center;
    border-radius: 10px;
    margin: 5vh auto;
    height: 50px;
}

.red {
    color: rgb(97, 171, 255) !important;
}

.card {
    width: 31%;
    opacity: 1;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
    margin: 10px 10px;
}

.cardTitle {
    color: black !important;
    padding: 10px;
}

.right {
    text-align: right;
}

.title {
    font-size: 24px;
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 50px;
    color: rgba(54, 54, 54, 1);
    text-align: left;
    vertical-align: top;
    margin-bottom: 10px;
}

.padding_ {
    padding: 30px;
}

.outer-container {
    /* position: relative;
		overflow: hidden; */
}

.inner-containe {
    display: flex;
    flex-wrap: wrap;
}

.inner-containe::-webkit-scrollbar {
    display: none;
}

.status_mine {
    display: flex;
    justify-content: flex-end;
    color: black;
}

.status_mine p {
    margin: 0 5px;
}

.chiose {
    font-weight: 700;
}

.form_head {
    background-color: rgba(227, 240, 255, 1);
    color: rgba(120, 120, 120, 1);
    display: flex;
    width: 100%;
    align-items: center;
    padding: 10px 0;
    margin-top: 10px;
}

.form_head p:first-child {
    /* width: 40%; */
    text-align: center;
}

.form_head p {
    /* width: 20%; */
    margin-bottom: 0;
    text-align: center;
}

.form_content {
    display: flex;
    color: black;
    align-items: center;
    padding: 15px 0;
}

.form_img {
    /* width: 40%; */
    display: flex;
}

.tip {
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    -webkit-box-orient: vertical;
}

.book_title {
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0px;
    line-height: 40px;
    color: rgba(0, 0, 0, 1);
    text-align: left;
    vertical-align: top;
}

.width_ {
    /* width: 20%; */
    text-align: center;
}
.money {
    color: rgba(255, 0, 0, 1);
}
/* <768px */
@media screen and (max-width: 768px) {
    .width_ {
        text-align: right;
    }
    .card {
        width: 100%;
    }
}

/* >=992 and  */
@media screen and (min-width: 992px) {
}

/* >=768px and <1200px */
@media screen and (min-width: 768px) and (max-width: 1200px) {
}

/* >=1200px */
@media screen and (min-width: 1200px) {
}
</style>
