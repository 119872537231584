import Vue from 'vue'
import App from './App.vue'
import router from './router'
import 'bootstrap/scss/bootstrap.scss'
import 'animate.css'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
import './assets/scss/style.scss'
import 'bootstrap'
import CoolLightBox from 'vue-cool-lightbox'
import VueScrollActive from 'vue-scrollactive'
import AOS from 'aos'
import 'aos/dist/aos.css'
import VueTypedJs from 'vue-typed-js'

// import VuevideoPlayer from 'vue-video-player';

// import 'video.js/dist/video-js.css';
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import {
	quillEditor
} from "vue-quill-editor";

// Vue.use(VuevideoPlayer);

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

Vue.use(ElementUI);


import axios from 'axios'
import VueResource from 'vue-resource'



Vue.config.productionTip = false
Vue.use(VueResource)


Vue.prototype.axios = axios;


Vue.config.productionTip = false
Vue.use(CoolLightBox)
Vue.use(VueScrollActive)
Vue.use(VueTypedJs)

new Vue({
	router,
	created() {
		AOS.init({
				duration: 800,
				once: true,
			}),
			axios,
			router
	},
	render: h => h(App)
}).$mount('#app')
