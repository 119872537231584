<template>
    <Layout>
       
	</Layout>
</template>

<script>
    import Layout from '../components/common/Layout'
    import Breadcrumb from '../components/elements/breadcrumb/Breadcrumb'
    import Separator from '../components/elements/separator/Separator'
    import SectionTitle from '../components/elements/sectionTitle/SectionTitle'
    import ServiceOne from '../components/elements/service/ServiceOne'
    import ServiceTwo from '../components/elements/service/ServiceTwo'
    import ServiceThree from '../components/elements/service/ServiceThree'
    import ServiceFour from '../components/elements/service/ServiceFour'
    import ServiceFive from '../components/elements/service/ServiceFive'

    export default {
        name: 'ServicePage',
        components: {ServiceOne, ServiceTwo, ServiceThree, ServiceFour, ServiceFive, Separator, SectionTitle, Breadcrumb, Layout}
    }
</script>