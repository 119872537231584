<template>
  <Layout header-class="" :show-newsletter="true" :show-cta="false">
    <div class="rn-demo-area" id="demo">
      <div class="container">
        <div class="row" style="padding: 30px 0">
          <div class="col-lg-8 col-md-8 col-12">
            <div
              style="
                display: flex;
                color: black;
                border-bottom: 1px solid rgba(97, 171, 255, 1);
              "
            >
              <p v-for="(item, index) in dataing.crumbs" :key="item.id">
                {{ item.name }} >
              </p>
              正文
            </div>
            <h4
              style="
                text-align: center;
                margin-top: 20px;
                line-height: 1.5;
                color: #333;
                word-wrap: break-word;
                word-break: normal;
              "
            >
              {{ dataing.title }}
            </h4>
            <div style="color: #999; font-size: 12px">
              <span>{{ dataing.createtime_text }}</span> |
              <span>编辑人：{{ dataing.editor }}</span> |
              <span>阅读：{{ dataing.views }}</span>
            </div>
            <div
              style="
                border: 1px #dedede solid;
                background-color: #f7f7f7;
                padding: 10px;
                position: relative;
                margin: 20px 0;
                color: black;
                display: flex;
                align-items: center;
              "
            >
              <strong class="zy"
                >摘
                <br />
                要</strong
              >
              <div style="font-size: 12px">
                {{ dataing.description }}
              </div>
            </div>
            <section class="bottom">
              <div v-html="dataing.content_text" style="color: black"></div>
            </section>
            <div class="go"></div>
            <div style="color: #333; display: flex">
              <!-- <img :src="qrcode" alt="" width="120px" /> -->
              <section style="width: 90%">
                <div
                  style="
                    border-bottom: 1px #dedede solid;
                    padding-left: 15px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                  "
                >
                  <div>
                    <!-- <strong>升学内参网</strong>
                                        <p style="color: #999; font-size: 12px">
                                            扫一扫关注官方微信
                                        </p> -->
                  </div>
                  <p class="go_btn" @click="goin()">
                    <img
                      src="../assets/images/go_before.png"
                      alt=""
                      width="25px"
                      style="margin-right: 5px"
                    />
                    {{ num }}
                  </p>
                </div>
                <p class="error" @click="goerror">报错？</p>
              </section>
            </div>
            <div
              class="addForm"
              style="
                background-color: white;
                color: #333;
                padding: 15px;
                border-radius: 10px;
              "
              v-if="error_form == true"
            >
              <div>标题：{{ dataing.title }}</div>
              <div style="margin-top: 10px">
                <p>错误内容</p>
                <el-input
                  type="textarea"
                  :rows="2"
                  placeholder="请输入内容"
                  v-model="content"
                >
                </el-input>
              </div>
              <div style="margin-top: 10px">
                <p>修正建议</p>
                <el-input
                  type="textarea"
                  :rows="2"
                  placeholder="请输入内容"
                  v-model="advise"
                >
                </el-input>
              </div>
              <div style="display: flex; justify-content: center">
                <p class="zero" @click="reset">重置</p>
                <p class="error_btn" @click="add_error">提交</p>
              </div>
            </div>
            <div style="color: #333; margin: 10px 0">
              <div @click="before()" v-if="dataing.before">
                上一篇文章：{{ dataing.before.title }}
              </div>
              <div @click="after()" v-if="dataing.after">
                下一篇文章：{{ dataing.after.title }}
              </div>
            </div>
            <div class="relate">
              <div class="relate_title">相关阅读</div>
              <div class="relate_content">
                <p
                  v-for="(item, index) in dataing.related"
                  style="width: 50%"
                  class="relateTitle"
                  @click="related(item.id)"
                  :key="item.id"
                >
                  {{ item.title }}
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-4 col-12 hotCard">
            <div class="hot">热门视频</div>
            <div style="width: 100%; height: 80px"></div>
            <div
              class="hot_"
              v-for="(item, index) in hot_videos"
              @click="detail_(item)"
              :key="item.id"
            >
              <img :src="item.image" alt="" />
              <p>{{ item.name }}</p>
              <p class="hot_tip">{{ item.buy_num }}次学习</p>
            </div>
            <div class="hot_article">热门文章</div>
            <div style="width: 100%; height: 80px"></div>
            <div
              class="articleList"
              v-for="(item, index) in hot_articles"
              @click="detail(item)"
              :key="item.id"
            >
              <p class="cirle">
                {{ index + 1 }}
              </p>
              <div>
                {{ item.title }}
              </div>
            </div>
            <div class="hot_article" style="margin-top: 15px">
              <img src="../assets/images/about.png" alt="" />
            </div>
            <div style="width: 100%; height: 160px"></div>
            <div style="text-align: center; padding-bottom: 40px">
              <p
                style="
                  color: #000;
                  margin: 0;
                  font-size: 25px;
                  line-height: 80px;
                "
              >
                关注官方微信
              </p>
              <img :src="ewm" alt="" style="width: 50%;" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "../components/common/Layout";
import Separator from "../components/elements/separator/Separator";
import SectionTitle from "../components/elements/sectionTitle/SectionTitle";
import AboutFour from "../components/elements/about/AboutFour";
import ServiceOne from "../components/elements/service/ServiceOne";
import Timeline from "../components/elements/timeline/Timeline";
import Counter from "../components/elements/counterUp/Counter";
import Brand from "../components/elements/brand/Brand";
import Team from "../components/elements/team/Team";
export default {
  name: "About",
  components: {
    Team,
    Brand,
    Counter,
    Timeline,
    ServiceOne,
    AboutFour,
    SectionTitle,
    Separator,
    Layout,
  },
  data() {
    return {
      ewm: "",
      id: "",
      model_id: "",
      page: 1,
      hot_videos: [],
      hot_articles: [],
      category: [],
      list_: [],
      dataing: {},
      num: 1,
      content: "",
      advise: "",
      error_form: false,
      qrcode: "",
    };
  },
  created() {
    this.id = this.$route.query.id;
    this.show();
    this.list();
    this.getData();
    let indexData = localStorage.getItem("indexData");
    console.log(JSON.parse(indexData).config.qrcode);
    if (indexData) {
      this.ewm = JSON.parse(indexData).config.qrcode;
    }
  },
  methods: {
    // 首页信息
    getData() {
      this.$http.get("https://cyfyjiaoxue.cn//api/index/index").then(
        function (res) {
          this.qrcode = res.body.data.config.qrcode;
          console.log(this.data, "hsuxhxhhhx");
        },
        function (res) {
          console.log(res); // 响应错误回调
        }
      );
    },
    reset() {
      this.content = "";
      this.advise = "";
    },
    goerror() {
      this.error_form == true
        ? (this.error_form = false)
        : (this.error_form = true);
    },
    add_error() {
      let this_ = this;
      this.$http({
        method: "post",
        url: "https://cyfyjiaoxue.cn//api/index/errorReport",
        params: {
          archive_id: this.dataing.id,
          content: this.content,
          advise: this.advise,
        },
        headers: {
          token: window.localStorage.getItem("token"),
        },
      })
        .then(({ data }) => {
          this.$alert("提交成功！", "提示", {
            confirmButtonText: "确定",
            callback: (action) => {
              this.content = "";
              this.advise = "";
            },
          });
        })
        .catch((res) => {
          console.log("1111111");
        });
    },
    related(id) {
      const { href } = this.$router.resolve({
        path: "/detail",
        query: {
          id: id,
        },
      });
      window.open(href);
      //关闭窗口的方法
      window.opener = null;
      window.open("", "_self", "");
      window.close();
    },
    before() {
      const { href } = this.$router.resolve({
        path: "/detail",
        query: {
          id: this.dataing.before.id,
        },
      });
      window.open(href);
      //关闭窗口的方法
      window.opener = null;
      window.open("", "_self", "");
      window.close();
    },
    after() {
      const { href } = this.$router.resolve({
        path: "/detail",
        query: {
          id: this.dataing.after.id,
        },
      });
      window.open(href);
      //关闭窗口的方法
      window.opener = null;
      window.open("", "_self", "");
      window.close();
    },
    // 点赞
    goin() {
      let this_ = this;
      this.$http({
        method: "post",
        url: "https://cyfyjiaoxue.cn//api/index/giveALike",
        params: {
          archive_id: this.dataing.id,
        },
        headers: {
          token: window.localStorage.getItem("token"),
        },
      })
        .then(({ data }) => {
          this.$alert("感谢您的点赞！", "提示", {
            confirmButtonText: "确定",
            callback: (action) => {
              this_.num = this_.num + 1;
            },
          });
        })
        .catch((res) => {
          console.log("1111111");
        });
    },
    // 热门视频详情点击
    detail_(item) {
      this.$router.push({
        path: `/videoDeatil?channel_id=${item.id}`,
      });
    },
    // 热门文章详情点击
    detail(item) {
      const { href } = this.$router.resolve({
        path: "/detail",
        query: {
          id: item.id,
        },
      });
      window.open(href);
      //关闭窗口的方法
      window.opener = null;
      window.open("", "_self", "");
      window.close();
    },
    free() {
      console.log(this.dataing.file_text);
      // window.open(this.dataing.outlink);
    },
    // 详情
    show() {
      let this_ = this;
      this.$http({
        method: "get",
        url: "https://cyfyjiaoxue.cn//api/index/archiveDetail",
        params: {
          id: this.id,
        },
        headers: {
          token: window.localStorage.getItem("token"),
        },
      })
        .then(({ data }) => {
          this_.dataing = data.data;
        })
        .catch((res) => {
          this.$alert(res.body.msg, "提示", {
            confirmButtonText: "确定",
            callback: (action) => {
              const { href } = this.$router.resolve({
                path: "/login",
              });
              window.open(href);
              //关闭窗口的方法
              window.opener = null;
              window.open("", "_self", "");
              window.close();
            },
          });
        });
    },
    // 热门
    list() {
      this.$http.get("https://cyfyjiaoxue.cn//api/index/hotArticle").then(
        function (res) {
          this.hot_videos = res.data.data.videos;
          this.hot_articles = res.data.data.articles;
        },
        function (res) {
          console.log(res); // 响应错误回调
        }
      );
    },
  },
};
</script>
<style scoped>
.about_list {
  display: flex;
  width: 100%;
}

.about_list p {
  font-size: 20.47px;
  font-weight: 400;
  letter-spacing: 0px;
  color: rgba(112, 112, 112, 100);
  text-align: left;
  vertical-align: top;
  margin: 0;
  width: 14%;
  line-height: 60px;
  text-align: center;
}

.about_line {
  width: 100%;
  height: 10px;
  opacity: 1;
  background: rgba(97, 171, 255, 1);
  color: rgba(97, 171, 255, 1);
  border-radius: 5px;
}

.active {
  color: #000 !important;
  display: flex;
  align-items: center;
}

.active::before {
  content: " ";
  width: 12px;
  height: 12px;
  opacity: 1;
  background: rgba(0, 0, 0, 1);
  color: #000;
  border-radius: 50%;
  margin-right: 5px;
  display: block;
}

.newCard {
  border-bottom: 0.85px solid rgba(166, 166, 166, 1);
  padding: 20px 0;
  cursor: pointer;
}

.newCard_title {
  font-size: 23.88px;
  font-weight: 500;
  letter-spacing: 0px;
  color: rgba(0, 0, 0, 1);
  text-align: justify;
  vertical-align: top;
  line-height: 50px;
}

.newCard_tip {
  font-size: 17.06px;
  font-weight: 400;
  letter-spacing: 0px;
  color: rgba(64, 64, 64, 1);
  text-align: justify;
}

.newCard_flex {
  display: flex;
}

.newCard_flex p {
  color: #000;
  margin: 0;
}

.hot {
  opacity: 1;
  background: rgba(97, 171, 255, 1);
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0px;
  color: rgba(255, 255, 255, 100);
  text-align: left;
  vertical-align: top;
  padding: 15px 20px;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  position: absolute;
  width: 100%;
  right: 0;
}

.hotCard {
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  background-color: white;
  position: relative;
  /* padding: 0 3% !important; */
}

.hot_ {
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 1.71px 3.41px 0px rgba(0, 0, 0, 0.25);
  margin-bottom: 20px;
  margin: 20px 5%;
}

.hot_ p {
  margin: 0%;
  color: #000;
  padding: 10px;
}

.hot_tip {
  text-align: right;
  font-size: 13.65px;
  font-weight: 400;
  letter-spacing: 0px;
  color: rgba(87, 87, 87, 1);
  text-align: right;
  vertical-align: top;
}

.hot_article {
  width: 100%;
  background: rgba(97, 171, 255, 1);
  position: absolute;
  left: 0;
  padding: 15px;
}

.articleList {
  color: #000;
  padding: 10px;
  display: flex;
  align-items: center;
}

.articleList .cirle {
  width: 25px;
  height: 25px;
  opacity: 1;
  background: rgba(97, 171, 255, 1);
  border-radius: 50%;
  color: white !important;
  text-align: center;
  margin: 0;
  margin-right: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.articleList div {
  line-height: 26px;
  width: 90%;
}

.active::before {
  content: " ";
  width: 0px;
  height: 0px;
  opacity: 1;
  background: rgba(0, 0, 0, 1);
  color: #000;
  border-radius: 50%;
  margin-right: 5px;
  display: block;
}

.el-pager li {
  margin: 0 5px;
  background-color: rgba(161, 157, 157, 1);
  color: #606266;
  min-width: 30px;
  border-radius: 2px;
}

.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: rgba(97, 171, 255, 1) !important;
  color: #fff !important;
  width: 30px;
  height: 30px;
  border-radius: 5px;
}

.el-pagination.is-background .el-pager li {
  margin: 0 5px;
  background-color: rgba(161, 157, 157, 1) !important;
  color: #fff !important;
  min-width: 50px !important;
  min-height: 50px;
  border-radius: 5px !important;
  font-size: 16px;
  line-height: 50px;
}

.el-pagination .btn-next .el-icon,
.el-pagination .btn-prev .el-icon {
  display: block;
  font-size: 20px !important;
  font-weight: 700;
  line-height: 50px;
}

.el-pagination {
  margin: 20px 0;
}

.white {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
}

.head {
  display: flex;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(166, 166, 166, 1);
}

.right_title {
  font-size: 30px;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 35px;
  color: rgba(0, 0, 0, 1);
  text-align: left;
  vertical-align: top;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-bottom: 10px;
}

.flex {
  display: flex;
  justify-content: space-between;
  color: rgba(82, 82, 82, 1);
}

.btn {
  width: 10vw;
  /* padding: 20px; */
  opacity: 1;
  border-radius: 22px;
  background: rgba(97, 171, 255, 1);
  color: white;
  font-size: 25px;
  height: 70px;
  /* line-height: 70px; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.left p {
  color: rgba(82, 82, 82, 1);
  margin: 0;
}

.left_fiex {
  display: flex;
  align-items: center;
}

.tip {
  margin: 0 5px;
}

.red {
  color: rgba(97, 171, 255, 1) !important;
}

.content {
  padding-top: 20px;
}

.content p {
  color: rgba(110, 110, 110, 1);
  margin-bottom: 10px;
}

.bottom {
  margin-top: 20px;
}

.bottom_title {
  font-size: 28px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 30px;
  color: rgba(0, 0, 0, 1);
  text-align: left;
  vertical-align: top;
  margin: 20px 0;
}

.btn {
  position: absolute;
  right: 15px;
}

.tag_tip {
  font-size: 14px;
  font-weight: 400;
  color: rgba(82, 82, 82, 1);
  text-align: left;
  vertical-align: top;
}

.go {
}

.go_btn {
  width: 100px;
  height: 60px;
  color: rgba(97, 171, 255, 1);
  /* background-color: ; */
  border: 1px #dedede solid;
  border-radius: 20px;
  font-size: 18px;
  /* 		margin: 20px 0; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.go_btn:hover {
  text-decoration: none;
  background-color: #99207f;
  color: #fff;
  border-color: #99207f;
}

.relate {
  width: 100%;
  margin: 10px auto;
  border: 1px #dedede solid;
  color: #333;
}

.relate_title {
  background-color: rgba(97, 171, 255, 1);
  height: 40px;
  color: white;
  line-height: 40px;
  padding: 0 15px;
}

.relate_content {
  display: flex;
  padding: 20px;
  flex-wrap: wrap;
  cursor: pointer;
}

.relateTitle:before {
  content: "•";
}

.relateTitle:hover {
  text-decoration: underline;
}

.error {
  text-align: right;
  font-size: 12px;
  margin-top: 15px;
}

.error:hover {
  text-decoration: underline;
  cursor: pointer;
}

.error_btn {
  background-color: rgba(97, 171, 255, 1);
  color: white;
  padding: 5px 15px;
  border-radius: 10px;
  margin: 20px;
  cursor: pointer;
}

.zero {
  background-color: gray;
  color: #fff;
  padding: 5px 15px;
  border-radius: 10px;
  margin: 20px;
  cursor: pointer;
}

.zy {
  width: 30px;
  text-align: center;
  font-size: 14px;
  margin-right: 5px;
}
</style>
