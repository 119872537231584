<template class="white">
    <Layout header-class="" :show-newsletter="true" :show-cta="false">
        <div class="line"></div>
        <div class="rn-demo-area" id="demo">
            <div class="container">
                <div class="row" style="">
                    <div class="col-lg-4 col-md-4 col-12 bottom">
                        <img
                            :src="now_img == '' ? detail.image : now_img"
                            alt=""
                        />
                        <div class="small_img">
                            <img
                                :src="item"
                                alt=""
                                v-for="(item, index) in detail.images"
                                @click="detail_img(item)"
                                :key="item.id"
                            />
                        </div>
                    </div>
                    <div class="col-lg-8 col-md-8 col-12 bottom">
                        <div class="title_">
                            {{ detail.name }}
                        </div>
                        <div class="goodNum">
                            商品货号：{{ detail.goods_no }}
                        </div>
                        <div class="introduction">
                            {{ detail.introduction }}
                        </div>
                        <div class="money">￥{{ detail.price }}</div>
                        <div>
                            <div class="num">数量</div>
                            <input
                                type="num"
                                placeholder="请输入数量"
                                class="Num"
                                v-model="num"
                            />
                        </div>
                        <button class="buy" @click="buy()">购买</button>
                    </div>
                </div>
                <div class="row">
                    <div class="sp_tip">商品介绍</div>
                    <div
                        v-html="detail.content"
                        style="width: 100%; margin-top: 20px"
                    ></div>
                </div>
            </div>
        </div>
    </Layout>
</template>

<script>
import Layout from "../components/common/Layout";
export default {
    name: "video",
    components: {
        Layout,
    },
    data() {
        return {
            id: "",
            detail: {},
            num: 1,
            now_img: "",
        };
    },
    created() {
        this.id = this.$route.query.id;
        this.list();
    },
    methods: {
        detail_img(item) {
            this.now_img = item;
            console.log(this.now_img);
        },
        buy() {
            this.$router.push({
                path: "/buy",
                query: {
                    goods_id: this.detail.id,
                    goods_num: this.num,
                },
            });
        },
        detail(item) {
            const { href } = this.$router.resolve({
                path: "/videoDeatil",
                query: {
                    id: item.id,
                    channel_id: item.channel_id,
                },
            });
            window.open(href);
            //关闭窗口的方法
            window.opener = null;
            window.open("", "_self", "");
            window.close();
        },
        // 列表
        list() {
            this.$http
                .get(
                    "https://cyfyjiaoxue.cn//api/goods/goodsDetail",
                    {
                        params: {
                            id: this.id,
                        },
                    },
                    {
                        emulateJSON: true,
                    }
                )
                .then(
                    function (res) {
                        if (res.data.code == 200) {
                            this.detail = res.data.data;
                        } else if (res.data.code == 114) {
                            this.$alert(res.data.msg, "提示", {
                                confirmButtonText: "确定",
                                callback: (action) => {
                                    this.$router.push({
                                        path: `/login`,
                                    });
                                },
                            });
                        }
                    },
                    function (res) {
                        console.log(res); // 响应错误回调
                    }
                );
        },
    },
};
</script>
<style scoped>
.white {
    background-color: white;
    padding: 10px 0;
    border-radius: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
}

.white p {
    padding: 5px 10px;
    width: auto;
    border: 1px solid rgba(179, 171, 171, 1);
    border-radius: 5px;
    text-align: center;
    margin: 0 10px;
    margin-bottom: 15px;
}

.carding {
    background-color: white;
    border: none;
    border-radius: 10px;
}

.card_title {
    display: flex;
    justify-content: space-between;
    align-content: center;
    border-bottom: 1px solid rgba(227, 227, 227, 1);
    padding: 10px;
    color: rgba(10, 10, 10, 1);
    margin: 0 10px;
}

.card_content {
    padding: 10px 15px;
    border-bottom: 1px solid rgba(227, 227, 227, 1);
    display: flex;
    justify-content: space-between;
}

.card_content p.title {
    color: rgba(23, 109, 194, 1) !important;
    margin: 0;
    width: 70%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.card_content p.date {
    color: rgba(145, 141, 141, 1) !important;
    margin: 0;
}

.bottom {
    margin-bottom: 15px;
}

.line {
    width: 100%;
    height: 5vh;
}

.title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: black;
}

.title p {
    margin: 0;
}

.title .title_ {
    font-size: 36px;
    font-weight: 500;
    letter-spacing: 0px;
    color: rgba(0, 0, 0, 1);
    text-align: left;
    vertical-align: top;
}

.title .lookmore {
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0px;
    color: rgba(107, 107, 107, 1);
    text-align: left;
    vertical-align: top;
    border: none;
}

.cardWhite {
    background-color: white;
}

.cardTitle p {
    margin: 0;
}

.cardTitle {
    padding: 10px;
}

.cardTitle .title {
    font-size: 16px;
}

.cardTitle .desc {
    font-size: 12px;
    color: rgba(87, 87, 87, 1);
}

.first_tip {
    padding: 15px 10px;
    display: flex;
    justify-content: space-between;
}

.first_tip p {
    margin-bottom: 0;
}

.first_tip .money {
    color: rgba(97, 171, 255, 1);
}

.white_card {
    background-color: white;
    width: 18%;
    margin: 10px 1%;
    box-sizing: border-box;
    padding: 10px 20px;
    color: black;
}

.flex {
    display: flex;
}

.book_title {
    margin-top: 10px;
    font-size: 14px;
    font-weight: 400;
    /* line-height: 28px; */
    color: rgba(0, 0, 0, 1);
    text-align: left;
    vertical-align: top;
}

.money {
    font-size: 22.54px;
    font-weight: 900;
    letter-spacing: 0px;
    line-height: 40px;
    color: rgba(212, 48, 48, 1);
    text-align: left;
    vertical-align: top;
}

.small_img {
    display: flex;
}

.small_img img {
    width: 14%;
    margin: 10px 1%;
}

body {
    background-color: white;
}

.title_ {
    font-size: 33px;
    font-weight: 500;
    line-height: 40px;
    color: rgba(0, 0, 0, 1);
    text-align: left;
    vertical-align: top;
}

.goodNum {
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0px;
    color: rgba(0, 0, 0, 1);
    text-align: left;
    vertical-align: top;
    margin: 20px 0;
}

.introduction {
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0px;
    /* line-height: 0px; */
    color: rgba(0, 0, 0, 1);
    text-align: justify;
    vertical-align: top;
    margin: 10px 0;
}

.Num {
    width: 30%;
    border: 1px solid rgba(204, 204, 204, 1);
}

.num {
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0px;
    color: rgba(0, 0, 0, 1);
    text-align: justify;
    vertical-align: top;
    margin: 10px 0;
}

.buy {
    width: 100%;
    opacity: 1;
    border-radius: 5.64px;
    background: rgba(212, 48, 48, 1);
    border: none;
    padding: 15px;
    color: white;
    margin-top: 25px;
}
.sp_tip {
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0px;
    color: rgba(0, 0, 0, 1);
    text-align: left;
    vertical-align: top;
}
</style>
