<template>
	<Layout header-class="" :show-newsletter="true" :show-cta="false">
		<div class="demo-slider-area slider-area bg-transparent slider-style-1 white">
			<div class="mb--20" data-aos="fade-up">
				<el-carousel :interval="5000">
					<el-carousel-item v-for="(item, index) in banner" :key="index">
						<div @click="banner_detail(item)">
							<img :src="item.icon" alt style="width: 100%;height: auto;position: relative;" />
							<p class="swiper">{{item.title}}</p>
						</div>
					</el-carousel-item>
				</el-carousel>
			</div>
		</div>

		<div class="rn-demo-area" id="demo">
			<div class="container">
				<div class="row" style="padding: 30px 0;">
					<div class="col-lg-8 col-md-8 col-12">
						<div class="about_list">
							<div v-for="(item,index) in category" :class="item.id==id?'active_font':''"
								@click="change(item)" :key="item.id">{{item.name}}</div>
						</div>
						<div class="about_line"></div>
						<section class="newCard" v-for="(item,index) in list_.data" :key="item.id" @click="detail(item)">
							<div class="newCard_title">
								<img :src="item.image" alt="" width="20px" height="20px">
								{{item.title}}
							</div>
							<div style="color: #BF8055;" v-show="item.archive_tags.length>1">
								<img src="../assets/images/tag.png" alt="" width="15px">
								<span v-for="(v,i) in item.archive_tags" :key="v.id" style="margin-right: 10px;font-size: 12px;"
									@click="detail(item)">{{v.title}}</span>
							</div>
							<p class="newCard_tip" style="font-size: 12px;margin-top: 10px;">
								{{item.description}}
							</p>
							<div class="newCard_flex">
								<p style="margin-right: 10px;">{{item.channel_name}}</p>
								<p>{{item.createtime_text}}</p>
							</div>
						</section>
						<!-- 分页 -->
						<el-pagination background layout="pager, next" :total="list_.total"
							:page-count="list_.current_page" :page-sizes="[10, 20, 30, 40, 50]" @current-change="changenum">
						</el-pagination>
					</div>
					<div class="col-lg-4 col-md-4 col-12 hotCard">
						<div class="hot">热门视频</div>
						<div style="width: 100%;height:80px;"></div>
						<div class="hot_" v-for="(item,index) in hot_videos" :key="item.id" @click="detail_(item)">
							<img :src="item.image" alt="">
							<p>{{item.name}}</p>
							<p class="hot_tip">{{item.buy_num}}次学习</p>
						</div>
						<div class="hot_article">热门文章</div>
						<div style="width: 100%;height:80px;"></div>
						<div class="articleList" v-for="(item,index) in hot_articles" :key="item.id" @click="detail(item)">
							<p class="cirle">
								{{index+1}}
							</p>
							<div>
								{{item.title}}
							</div>
						</div>
						<div class="hot_article" style="margin-top: 15px;">
							<img src="../assets/images/about.png" alt="">
						</div>
						<div style="width: 100%;height:160px;"></div>
						<div style="text-align: center;padding-bottom: 40px;">
							<p style="color: #000;margin: 0;font-size: 25px;line-height: 80px;">关注官方微信</p>
							<img :src="ewm" alt="" style="width: 50%;">
						</div>
					</div>

				</div>
			</div>
		</div>

	</Layout>
</template>

<script>
	import Layout from '../components/common/Layout'
	import Separator from '../components/elements/separator/Separator'
	import SectionTitle from '../components/elements/sectionTitle/SectionTitle'
	import AboutFour from '../components/elements/about/AboutFour'
	import ServiceOne from '../components/elements/service/ServiceOne'
	import Timeline from '../components/elements/timeline/Timeline'
	import Counter from '../components/elements/counterUp/Counter'
	import Brand from '../components/elements/brand/Brand'
	import Team from '../components/elements/team/Team'

	export default {
		name: 'About',
		components: {
			Team,
			Brand,
			Counter,
			Timeline,
			ServiceOne,
			AboutFour,
			SectionTitle,
			Separator,
			Layout
		},
		data() {
			return {
				id: '',
				model_id: '',
				page: 1,
				hot_videos: [],
				hot_articles: [],
				category: [],
				list_: [],
				banner: [],
				ewm: ''
			}
		},
		onLoad(option) {
			console.log(option)

		},
		created() {
			this.id = this.$route.query.id;
			this.model_id = this.$route.query.model_id;
			this.show()
			this.list()
			this.bannerShow()
			// 本地取indexData
			let indexData = localStorage.getItem('indexData');
			console.log(JSON.parse(indexData).config.qrcode);
			if (indexData) {
				this.ewm = JSON.parse(indexData).config.qrcode;
			}
		},
		methods: {
			changeJump() {

			},
			banner_detail(item) {
				this.$router.push({
					path: `/detail?id=${item.id}`
				})
			},
			bannerShow() {
				this.$http.get('https://cyfyjiaoxue.cn//api/index/getBannerList', {
					params: {
						source_channel_id: this.id
					}
				}, {
					emulateJSON: true
				}).then(function(res) {
					if (res.data.code == 200) {
						this.banner = res.data.data;
						
					} else if (res.data.code == 114) {
						this.$alert(res.data.msg, '提示', {
							confirmButtonText: '确定',
							callback: action => {
								this.$router.push({
									path: `/login`
								})
							}
						});
					}
				}, function(res) {
					console.log(res) // 响应错误回调
				})
			},
			change(item) {
				this.id = item.id;
				this.show()
				
			},
			detail_(item) {
				this.$router.push({
					path: `/videoDeatil?channel_id=${item.id}`
				})
			},
			// 列表详情点击
			detail(item) {
				console.log(item)
				this.$router.push({
					path: `/detail?id=${item.id}`
				})

			},
			// 分页点击
			changenum(e) {
				this.page = e;
				this.show()
			},
			// 
			show() {
				this.$http.get('https://cyfyjiaoxue.cn//api/index/navJump', {
					params: {
						id: this.id,
						model_id: this.model_id,
						page: this.page,
						row: 10,
						region_id: 0
					}
				}, {
					emulateJSON: true
				}).then(function(res) {

					if (res.data.code == 200) {
						this.category = res.data.data.category;
						this.list_ = res.data.data.list;
						console.log(this.list_)
					} else if (res.data.code == 114) {
						this.$alert(res.data.msg, '提示', {
							confirmButtonText: '确定',
							callback: action => {
								this.$router.push({
									path: `/login`
								})
							}
						});

					}
				}, function(res) {
					console.log(res) // 响应错误回调
				})
			},
			// 热门
			list() {
				this.$http.get('https://cyfyjiaoxue.cn//api/index/hotArticle').then(function(res) {
					this.hot_videos = res.data.data.videos;
					this.hot_articles = res.data.data.articles;
				}, function(res) {
					console.log(res) // 响应错误回调
				})

			}
		}
	}
</script>
<style scoped>
	.about_list {
		display: flex;
		width: 100%;
	}

	.about_list div {
		font-size: 16px;
		font-weight: 400;
		letter-spacing: 0px;
		color: rgb(112, 112, 112);
		text-align: left;
		vertical-align: top;
		margin: 0;
		width: 14%;
		line-height: 60px;
		text-align: center;
		font-weight: 550;
	}

	.about_line {
		width: 100%;
		height: 10px;
		opacity: 1;
		background: rgba(97, 171, 255, 1);
		color: rgba(97, 171, 255, 1);
		border-radius: 5px;
	}

	.active {
		color: #000 !important;
		display: flex;
		align-items: center;
	}

	.active::before {
		content: ' ';
		width: 12px;
		height: 12px;
		opacity: 1;
		background: rgba(0, 0, 0, 1);
		color: #000;
		border-radius: 50%;
		margin-right: 5px;
		display: block;
	}

	.newCard {
		border-bottom: 0.85px solid rgba(166, 166, 166, 1);
		padding: 20px 0;
		cursor: pointer;
	}

	.newCard_title {
		font-size: 16px;
		font-weight: 500;
		letter-spacing: 0px;
		color: rgba(0, 0, 0, 1);
		text-align: justify;
		vertical-align: top;
		line-height: 40px;
	}

	.newCard_title:hover {
		text-decoration: underline;
		color: rgb(97, 171, 255) !important;
	}

	.newCard_tip {
		font-size: 17.06px;
		font-weight: 400;
		letter-spacing: 0px;
		color: rgba(64, 64, 64, 1);
		text-align: justify;
		word-wrap: break-word;
		word-break: normal;
		display: -webkit-box;
		overflow: hidden;
		/*超出隐藏*/
		text-overflow: ellipsis;
		/*隐藏后添加省略号*/
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 2;
	}

	.newCard_flex {
		display: flex;
	}

	.newCard_flex p {
		color: #000;
		margin: 0;
	}

	.hot {
		opacity: 1;
		background: rgba(97, 171, 255, 1);
		color: #fff;
		font-size: 16px;
		font-weight: 400;
		letter-spacing: 0px;
		color: rgba(255, 255, 255, 100);
		text-align: left;
		vertical-align: top;
		padding: 15px 20px;
		border-top-right-radius: 20px;
		border-top-left-radius: 20px;
		position: absolute;
		width: 100%;
		right: 0;
		font-weight: 550;
	}

	.hotCard {
		border-top-right-radius: 20px;
		border-top-left-radius: 20px;
		background-color: white;
		position: relative;
		/* padding: 0 3% !important; */
	}

	.hot_ {
		background: rgba(255, 255, 255, 1);
		box-shadow: 0px 1.71px 3.41px 0px rgba(0, 0, 0, 0.25);
		margin-bottom: 20px;
		margin: 20px 5%;
	}

	.hot_ p {
		margin: 0%;
		color: #000;
		padding: 5px 10px;
		font-size: 16px;
	}

	.hot_tip {
		text-align: right;
		font-size: 14px !important;
		font-weight: 400;
		letter-spacing: 0px;
		color: rgba(87, 87, 87, 1);
		text-align: right;
		vertical-align: top;
	}

	.hot_article {
		width: 100%;
		background: rgba(97, 171, 255, 1);
		position: absolute;
		left: 0;
		padding: 15px;
	}

	.articleList {
		color: #000;
		padding: 10px;
		display: flex;
		align-items: center;

	}

	.articleList .cirle {
		width: 25px;
		height: 25px;
		opacity: 1;
		background: rgba(97, 171, 255, 1);
		border-radius: 50%;
		color: white !important;
		text-align: center;
		margin: 0;
		margin-right: 15px;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.articleList div {
		line-height: 26px;
		width: 90%;
	}

	.active::before {
		content: ' ';
		width: 0px;
		height: 0px;
		opacity: 1;
		background: rgba(0, 0, 0, 1);
		color: #000;
		border-radius: 50%;
		margin-right: 5px;
		display: block;
	}

	.el-pager li {
		margin: 0 5px;
		background-color: rgba(161, 157, 157, 1);
		color: #606266;
		min-width: 30px;
		border-radius: 2px;
	}

	.el-pagination.is-background .el-pager li:not(.disabled).active {
		background-color: rgba(97, 171, 255, 1) !important;
		color: #FFF !important;
		width: 30px;
		height: 30px;
		border-radius: 5px;
	}

	.el-pagination.is-background .el-pager li {
		margin: 0 5px;
		background-color: rgba(161, 157, 157, 1) !important;
		color: #fff !important;
		min-width: 50px !important;
		min-height: 50px;
		border-radius: 5px !important;
		font-size: 16px;
		line-height: 50px;
	}

	.el-pagination .btn-next .el-icon,
	.el-pagination .btn-prev .el-icon {
		display: block;
		font-size: 20px !important;
		font-weight: 700;
		line-height: 50px;
	}

	.el-pagination {
		margin: 20px 0;
	}

	.active_font {
		color: black !important;
	}

	.line {
		width: 100%;
		height: 40px;
	}
</style>


