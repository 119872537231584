<template class="white">
	<Layout header-class="" :show-newsletter="true" :show-cta="false">
		<div class="line"></div>
		<div class="rn-demo-area" id="demo">
			<div class="container">
				<div class="row white_card" style="">
					<div class="col-lg-12 col-md-12 col-12 bottom card_title">
						确认商品信息
					</div>
					<div class="col-lg-12 col-md-12 col-12 bottom blue_back">
						<div class="sp">商品</div>
						<div>数量</div>
						<div>售价(元)</div>
					</div>
					<div class="col-lg-12 col-md-12 col-12 bottom sp_card">
						<div class="form_img">
							<img :src="detail.image" alt="" width="135px" style="margin-right: 5px;">
							<div>
								<section class="book_title">{{detail.name}}</section>
								<p class="tip">{{detail.introduction}}</p>
							</div>
						</div>
						<div class="width_">{{goods_num}}</div>
						<div class="width_ money">{{detail.price}}</div>
					</div>

				</div>
				<div class="row white_card" style="">
					<div class="col-lg-12 col-md-12 col-12 bottom card_title">
						填写订单信息
					</div>
					<div class="col-lg-12 col-md-12 col-12 bottom">
						<div class="tr">
							<div>
								<span class="red">*</span>
								联系人：
							</div>
							<input type="text" placeholder="请填写联系人" class="ipt" v-model="contact_person">
						</div>
						<div class="tr">
							<div>
								<span class="red">*</span>
								手机号：
							</div>
							<input type="number" placeholder="请填写手机号码" class="ipt" v-model="contact_mobile">
						</div>
						<div class="tr">
							<div>
								<span class="red">*</span>
								收件地址：
							</div>
							<input type="text" placeholder="请填写收件地址" class="ipt" v-model="receive_address">
						</div>
					</div>
					<div class="col-lg-12 col-md-12 col-12 bottom card_title">
						确认订单信息
					</div>
					<div class="tr">
						<div>
							订单金额：
						</div>
						<div class="money_">
							￥{{Number(detail.price) * goods_num}}
						</div>
					</div>
					<div class="pay" @click="pay()">
						去支付
					</div>

				</div>


			</div>
		</div>

	</Layout>

</template>

<script>
	import Layout from '../components/common/Layout'
	export default {
		name: 'video',
		components: {
			Layout,
		},
		data() {
			return {
				id: '',
				detail: {},
				goods_num: 1,
				contact_person: '',
				contact_mobile: '',
				receive_address: ''
			}
		},
		created() {
			this.id = this.$route.query.goods_id;
			this.goods_num = this.$route.query.goods_num;
			this.list()

		},
		methods: {
			pay() {
				this.$http.post('https://cyfyjiaoxue.cn//api/goods_order/addOrder', {
					goods_id: this.id,
					goods_num: this.goods_num,
					contact_person: this.contact_person,
					contact_mobile: this.contact_mobile,
					receive_address: this.receive_address
				}, {
					emulateJSON: true,
					headers: {
						"content-type": "application/x-www-form-urlencoded",
						"token": window.localStorage.getItem('token'),
					}
				}).then(function(res) {
					if (res.data.code == 200) {


					} else if (res.data.code == 100) {
						this.$alert(res.data.msg, '提示', {
							confirmButtonText: '确定',
							callback: action => {

							}
						});

					} else if (res.data.code == 114) {
						this.$alert(res.data.msg, '提示', {
							confirmButtonText: '确定',
							callback: action => {
								this.$router.push({
									path: `/login`
								})
							}
						});

					} else if (res.data.code == 401) {
						this.$alert(res.data.msg, '提示', {
							confirmButtonText: '确定',
							callback: action => {
								this.$router.push({
									path: `/login`
								})
							}
						});

					}

				}, function(res) {
					console.log(res) // 响应错误回调
				})

			},
			lookmore(item) {
				console.log(item)
				this.$router.push({
					path: '/videoDeatil',
					query: {
						channel_id: item.id
					},
				});
			},
			detail(item) {
				const {
					href
				} = this.$router.resolve({
					path: '/videoDeatil',
					query: {
						id: item.id,
						channel_id: item.channel_id
					},
				});
				window.open(href);
				//关闭窗口的方法
				window.opener = null
				window.open('', '_self', '')
				window.close()
			},
			// 列表
			list() {
				this.$http.get('https://cyfyjiaoxue.cn//api/goods/goodsDetail', {
					params: {
						id: this.id,
					}
				}, {
					emulateJSON: true
				}).then(function(res) {
					if (res.data.code == 200) {
						this.detail = res.data.data;
					} else if (res.data.code == 114) {
						this.$alert(res.data.msg, '提示', {
							confirmButtonText: '确定',
							callback: action => {
								this.$router.push({
									path: `/login`
								})
							}
						});

					}

				}, function(res) {
					console.log(res) // 响应错误回调
				})

			}
		}
	}
</script>
<style scoped>
	.white {
		background-color: white;
		padding: 10px 0;
		border-radius: 10px;
		display: flex;
		flex-wrap: wrap;
		justify-content: left;

	}

	.white p {
		padding: 5px 10px;
		width: auto;
		border: 1px solid rgba(179, 171, 171, 1);
		border-radius: 5px;
		text-align: center;
		margin: 0 10px;
		margin-bottom: 15px;
	}

	.carding {
		background-color: white;
		border: none;
		border-radius: 10px;
	}

	.card_title {
		color: rgba(10, 10, 10, 1);
		padding: 20px;
		display: flex;
	}

	.card_title::before {
		content: ' ';
		width: 10px;
		height: 30px;
		background-color: rgba(255, 0, 0, 1);
		color: rgba(255, 0, 0, 1);
		display: block;
		margin-right: 10px;
	}

	.card_content {
		padding: 10px 15px;
		border-bottom: 1px solid rgba(227, 227, 227, 1);
		display: flex;
		justify-content: space-between;
	}

	.card_content p.title {
		color: rgba(23, 109, 194, 1) !important;
		margin: 0;
		width: 70%;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	.card_content p.date {
		color: rgba(145, 141, 141, 1) !important;
		margin: 0;
	}

	.bottom {
		margin-bottom: 15px;
	}

	.line {
		width: 100%;
		height: 5vh;
	}

	.title {
		display: flex;
		justify-content: space-between;
		align-items: center;
		color: black;
	}

	.title p {
		margin: 0;
	}

	.title .title_ {
		font-size: 36px;
		font-weight: 500;
		letter-spacing: 0px;
		color: rgba(0, 0, 0, 1);
		text-align: left;
		vertical-align: top;
	}

	.title .lookmore {
		font-size: 16px;
		font-weight: 400;
		letter-spacing: 0px;
		color: rgba(107, 107, 107, 1);
		text-align: left;
		vertical-align: top;
		border: none;
	}

	.cardWhite {
		background-color: white;
	}

	.cardTitle p {
		margin: 0;
	}

	.cardTitle {
		padding: 10px;
	}

	.cardTitle .title {
		font-size: 16px;
	}

	.cardTitle .desc {
		font-size: 12px;
		color: rgba(87, 87, 87, 1);
	}

	.first_tip {
		padding: 15px 10px;
		display: flex;
		justify-content: space-between;
	}

	.first_tip p {
		margin-bottom: 0;
	}

	.first_tip .money {
		color: rgba(97, 171, 255, 1);
	}

	.white_card {
		width: 100%;
		border-radius: 11.27px;
		background: rgba(255, 255, 255, 1);
		box-shadow: 0px 2.25px 11.27px 0px rgba(0, 0, 0, 0.25);
		margin-bottom: 20px;

	}

	.flex {
		display: flex;
	}

	.book_title {
		margin-top: 10px;
		font-size: 14px;
		font-weight: 400;
		/* line-height: 28px; */
		color: rgba(0, 0, 0, 1);
		text-align: left;
		vertical-align: top;
	}

	.money {
		font-size: 20px;
		font-weight: 400;
		letter-spacing: 0px;
		color: rgba(255, 0, 0, 1) !important;
		text-align: left;
		vertical-align: top;

	}

	.small_img {
		display: flex;
	}

	.small_img img {
		width: 14%;
		margin: 10px 1%;
	}

	body {
		background-color: white;
	}

	.title_ {
		font-size: 33px;
		font-weight: 500;
		line-height: 40px;
		color: rgba(0, 0, 0, 1);
		text-align: left;
		vertical-align: top;
	}

	.goodNum {
		font-size: 12px;
		font-weight: 400;
		letter-spacing: 0px;
		color: rgba(0, 0, 0, 1);
		text-align: left;
		vertical-align: top;
		margin: 20px 0;
	}

	.introduction {
		font-size: 16px;
		font-weight: 400;
		letter-spacing: 0px;
		/* line-height: 0px; */
		color: rgba(0, 0, 0, 1);
		text-align: justify;
		vertical-align: top;
		margin: 10px 0;
	}

	.Num {
		width: 30%;
		border: 1px solid rgba(204, 204, 204, 1);
	}

	.num {
		font-size: 14px;
		font-weight: 400;
		letter-spacing: 0px;
		color: rgba(0, 0, 0, 1);
		text-align: justify;
		vertical-align: top;
		margin: 10px 0;
	}

	.buy {
		width: 100%;
		opacity: 1;
		border-radius: 5.64px;
		background: rgba(212, 48, 48, 1);
		border: none;
		padding: 15px;
		color: white;
		margin-top: 25px;
	}

	.sp_tip {
		font-size: 20px;
		font-weight: 500;
		letter-spacing: 0px;
		color: rgba(0, 0, 0, 1);
		text-align: left;
		vertical-align: top;
	}

	.blue_back {
		background: rgba(227, 240, 255, 1);
		border: 1.13px solid rgba(149, 197, 252, 1);
		color: rgba(120, 120, 120, 1);
		display: flex;
		align-items: center;
		padding: 15px 0;
	}

	.blue_back div {
		width: 25%;
		text-align: center;
	}

	.blue_back div.sp {
		width: 50%;
	}

	.form_img {
		width: 50%;
		display: flex;
		color: black;
	}

	.tip {
		text-overflow: -o-ellipsis-lastline;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 3;
		line-clamp: 3;
		-webkit-box-orient: vertical;
	}

	.book_title {
		text-overflow: -o-ellipsis-lastline;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 1;
		line-clamp: 1;
		-webkit-box-orient: vertical;
		font-size: 20px;
		font-weight: 500;
		letter-spacing: 0px;
		line-height: 40px;
		color: rgba(0, 0, 0, 1);
		text-align: left;
		vertical-align: top;

	}

	.width_ {
		width: 25%;
		text-align: center;
		color: black;
	}

	.sp_card {
		display: flex;
		align-items: center;
	}

	.ipt {
		border-radius: 11.27px;
		border: 1.13px solid rgba(196, 196, 196, 1);
		width: 30%;
		padding: 5px;
		color: black;
	}

	.tr {
		display: flex;
		align-items: center;
		color: black;
		margin: 10px 0;
	}

	.tr div {
		width: 12%;
		text-align: center;
	}

	.money_ {
		font-size: 30px;
		color: rgba(232, 0, 0, 1);
		text-align: left !important;
		vertical-align: top;
	}

	.pay {
		width: 95%;
		margin: 20px auto;
		text-align: center;
		padding: 15px;
		opacity: 1;
		border-radius: 15px;
		background: rgba(212, 48, 48, 1);

	}
</style>
