<template>
  <Layout :footer-style="2">
    <Separator />
    <section class="container" style="margin: 20px auto">
      <div class="row flex">
        <div class="col-lg-6 col-md-12 col-12">
          <div  class="logoBox">
            <img :src="website_icon" alt="" class="logo" />
          </div>
        </div>
        <div class="col-lg-6 col-md-12 col-12 white">
          <section class="kuang">
            <div class="login">注册</div>
            <p style="color: rgba(152, 159, 188, 1)">输入用户信息</p>
            <label for="" class="account">
              <input
                type="tel"
                placeholder="请输入姓名"
                style="color: #000; padding: 5px 0; outline: none"
                v-model="username"
              />
              <img src="../assets/images/account.png" alt="" />
            </label>
            <label for="" class="account">
              <el-select v-model="keshiSelectVal" placeholder="所在科室">
                <el-option
                  v-for="item in keshiOptions"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </label>
            <label for="" class="account">
              <el-select v-model="shenfenSelectVal" placeholder="身份">
                <el-option
                  v-for="item in shenfenOption"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </label>
            <label for="" class="account">
              <input
                type="tel"
                placeholder="请输入手机号"
                style="color: #000; padding: 5px 0; outline: none"
                v-model="mobile"
              />
              <img src="../assets/images/account.png" alt="" />
            </label>
            <label for="" class="account">
              <input
                type="text"
                placeholder="验证码"
                style="color: #000; padding: 5px 0"
                v-model="code"
              />
              <div class="getcode" @click="Send">
                {{ send ? "验证码" : second + "s" }}
              </div>
            </label>
            <label for="" class="account">
              <input
                type="password"
                placeholder="请输入密码"
                style="color: #000; padding: 5px 0"
                v-model="password"
              />
              <img src="../assets/images/password.png" alt="" />
            </label>

            <div class="footer">
              <button class="login" @click="login">注册</button>
            </div>
          </section>
        </div>
      </div>
    </section>
  </Layout>
</template>

<script>
import Layout from "../components/common/Layout";
import Icon from "../components/icon/Icon";
import Separator from "../components/elements/separator/Separator";
import SectionTitle from "../components/elements/sectionTitle/SectionTitle";

export default {
  name: "Demo",
  components: {
    Icon,
    Layout,
    Separator,
    SectionTitle,
  },
  data() {
    return {
      website_icon: "",
      nav: [],
      mobile: "",
      username: "",
      password: "",
      code: "",
      area: "",
      send: true, //按钮可以点击
      s: 60, //默认倒计时
      second: 0,
      selectedOptions: "",
      keshiOptions: [
        {
          value: "keshi1",
          label: "科室1",
        },
        {
          value: "keshi2",
          label: "科室2",
        },
      ], //科室选项
      keshiSelectVal: "", //科室选择值
      shenfenOption: [
        {
          id: 1,
          label: "学生",
          value: "1",
        },
        {
          id: 2,
          label: "教师",
          value: "2",
        },
      ], //身份选项
      shenfenSelectVal: "", //身份选择值
    };
  },
  created() {
    const vm = this;
    this.getkeshiData();
  },
  mounted() {
    if (window.localStorage.getItem("indexData")) {
      let indexData = JSON.parse(window.localStorage.getItem("indexData"));

      this.website_icon = indexData.website_icon;
    } else {
      this.apidocApi();
    }
  },
  methods: {
    async apidocApi() {
      await this.$http({
        method: "post",
        url: "https://cyfyjiaoxue.cn//api/index/index",
        headers: {
          token: window.localStorage.getItem("token"),
        },
      })
        .then((res) => {
          //res 存入本地
          window.localStorage.setItem(
            "indexData",
            JSON.stringify(res.data.data)
          );

          this.website_icon = res.data.data.website_icon;
        })
        .catch((res) => {});
    },
    handleChange(val) {
      this.area = this.$refs.cascaderAddr
        .getCheckedNodes()[0]
        .pathLabels.toString();
    },
    // 倒计时
    time() {
      let that = this;
      that.second = that.s;
      let interval = setInterval(function () {
        if (that.second == 1) {
          that.send = true;
          that.second = that.s;
          clearInterval(interval);
        } else {
          that.second--;
        }
      }, 1000);
    },
    Send() {
      // 防止多次重复点击
      if (!this.send) {
        return false;
      }
      let that = this;
      this.$http
        .post(
          "https://cyfyjiaoxue.cn//api/index/sendMsgCode",
          {
            mobile: this.mobile,
            event: "register",
          },
          {
            emulateJSON: true,
          }
        )
        .then(
          function (res) {
            if (res.body.code == 200) {
              that.$message(res.body.msg);
              that.send = false;
              that.time(); // 倒计时
            } else {
              that.$alert(res.body.msg, "提示", {
                confirmButtonText: "确定",
                callback: (action) => {},
              });
              // alert(res.msg)
            }
          },
          function (res) {
            console.log(res); // 响应错误回调
          }
        );
    },
    // 登录
    login() {
      this.$http
        .post(
          "https://cyfyjiaoxue.cn/api/index/register",
          {
            mobile: this.mobile,
            password: this.password,
            code: this.code,
            nickname: this.username, //姓名
            office_id: this.keshiSelectVal, //科室
            role: this.shenfenSelectVal, //身份
          },
          {
            emulateJSON: true,
          }
        )
        .then(
          function (res) {
            if (res.body.code == 200) {
              this.$alert(res.body.msg, "提示", {
                confirmButtonText: "确定",
                callback: (action) => {
                  this.$router.replace("/login"); //跳转的页面
                },
              });
            } else {
              this.$alert(res.body.msg, "提示", {
                confirmButtonText: "确定",
                callback: (action) => {},
              });
              // alert(res.msg)
            }
            console.log(res); // 响应成功回调
          },
          function (res) {
            console.log(res); // 响应错误回调
          }
        );
    },
    // 获取科室列表
    getkeshiData() {
      this.$http.get("https://cyfyjiaoxue.cn/api/index/officelist").then(
        function (res) {
          this.keshiOptions = res.body.data;
        },
        function (res) {
          console.log(res); // 响应错误回调
        }
      );
    },
  },
};
</script>
<style scoped>
.logoBox {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(97, 171, 255);
  border-radius: 5px;
  height: 100%;
  min-height: calc(100vh - 250px);
  
}
.logo {
  height: 95px;
}

/deep/.el-cascader-node__label {
  flex: 1;
  padding: 0 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: black !important;
}

/deep/.el-cascader .el-input .el-input__inner {
  text-overflow: ellipsis;
  color: black !important;
  padding: 0 !important;
}

/deep/.el-cascader-node__label {
  color: black !important;
}

/deep/.account .el-cascader-node {
  color: black !important;
}

/deep/ul li {
  color: #000 !important;
}

body {
  background-color: rgba(245, 245, 245, 1) !important;
}

.white {
  background-color: white !important;
}

.Leftcard {
  color: #000;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.25);
  padding: 0 !important;
  box-sizing: border-box !important;
}

.Leftcard_title {
  color: #fff;
  background: rgba(97, 171, 255, 1);
  width: 100%;
  font-size: 20px;
  padding: 5px 15px;
  margin-bottom: 10px;
}

.Leftcard_content {
  padding: 5px 15px;
}

.Leftcard_content p {
  color: #000;
  margin: 0;
  line-height: 35px;
  font-size: 15px;
}

.red {
  color: red !important;
}

.content_title {
  font-size: 17px;
}

.consult {
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 1.71px 5.12px 0px rgba(0, 0, 0, 0.25);
  padding: 0 !important;
  box-sizing: border-box !important;
}

.consult_title {
  display: flex;
  border-bottom: 0.85px solid rgba(224, 224, 224, 1);
  color: #000;
  justify-content: space-between;
  align-items: flex-end;
  padding: 0px 15px;
  padding-top: 6px;
  margin-bottom: 10px;
}

.consult_title p {
  color: #000;
  margin: 0 !important;
}

.consult_title_ {
  border-bottom: 10px solid rgba(97, 171, 255, 1);
}

.lookmore {
  font-size: 14px;
  border-bottom: 5px solid white;
}

.new_list {
  display: flex;
  border-bottom: 0.85px solid rgba(224, 224, 224, 1);
  color: #000;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
}

.new_list p {
  margin: 0 !important;
  color: #000;
  font-size: 14px;
}

.new_date {
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 0px;
  color: rgba(97, 171, 255, 1) !important;
  text-align: left;
  vertical-align: top;
}

.new_title {
  width: 75%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

@media (min-width: 992px) {
  .col-lg-4 {
    flex: 0 0 auto;
    width: 31.5% !important;
    margin: 10px;
  }
}

.joinIn {
  color: rgba(101, 103, 112, 1);
  text-align: right;
  padding: 0 25px;
}

.news {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: center;
  border-bottom: 8px solid rgba(97, 171, 255, 1);
  border-radius: 5px;
}

.news p {
  color: #000;
  margin: 0;
}

.newsTiele {
  font-size: 20.47px;
  font-weight: 550;
  color: rgba(0, 0, 0, 1);
  text-align: justify;
  vertical-align: top;
}

.newsLeft {
  text-align: center;
}

.newsLeft .title {
  font-size: 20.47px;
  font-weight: 400;
  letter-spacing: 0px;
  color: rgba(0, 0, 0, 1);
  text-align: justify;
  vertical-align: top;
  text-align: center;
  margin: 10px 0 20px;
}

.newsLeft .tip {
  font-size: 17.06px;
  font-weight: 400;
  letter-spacing: 0px;
  color: rgba(145, 145, 145, 1);
  text-align: justify;
  vertical-align: top;
}

.newsList .new_date {
  font-size: 15.35px;
  font-weight: 400;
  letter-spacing: 0px;
  color: rgba(97, 97, 97, 1) !important;
  text-align: justify;
  vertical-align: top;
}

.newsList {
  border-bottom: 0.85px solid rgba(166, 166, 166, 1);
  padding: 20px 0 5px;
}

.newsList p {
  color: #000;
  margin: 0;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.Backing {
  background-color: rgba(97, 171, 255, 1);
  color: #fff;
  padding: 10px 10px;
  font-size: 20px;
}

.Backing img {
  width: 44px;
  height: 30px;
}

.meaasge {
  display: flex;
  margin: 20px 0;
}

.meaasge .meaasge_date {
  color: rgba(97, 97, 97, 1);
  background: rgba(204, 204, 204, 1);
  width: 50px;
  height: 50px;
  opacity: 1;
  background: rgba(204, 204, 204, 1);
  border-radius: 9px;
  margin-right: 15px;
}

.meaasge .meaasge_date p {
  margin: 0 auto;
  text-align: center;
  color: rgba(97, 97, 97, 1);
  height: 25px;
}

.meaasge .meaasge_date .day {
  font-size: 15px;
}

.meaasge .meaasge_date .month {
  font-size: 6px;
}

.list {
  color: #000;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0px;
  color: rgba(97, 97, 97, 1);
  text-align: justify;
  vertical-align: top;
  line-height: 45px;
}

.vedio {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.vedio p {
  margin: 0;
  color: #000;
  font-size: 25px;
  font-weight: 500;
  letter-spacing: 0px;
  color: rgba(0, 0, 0, 1);
  text-align: left;
  vertical-align: top;
}

.card_white {
  padding: 13px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 1.71px 3.41px 0px rgba(0, 0, 0, 0.25);
  margin-bottom: 20px;
}

.card_white .flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 13.65px;
  font-weight: 400;
  letter-spacing: 0px;
  color: rgba(87, 87, 87, 1);
  text-align: left;
  vertical-align: top;
}

span.red {
  color: rgba(97, 171, 255, 1);
}

.card_white .name {
  font-size: 17.06px;
  font-weight: 400;
  letter-spacing: 0px;
  color: rgba(0, 0, 0, 1);
  text-align: left;
  vertical-align: top;
  margin-bottom: 20px;
}

.card_white p {
  margin: 0;
  color: #000;
}

.white {
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex {
  border-radius: 30px;
}

.kuang {
  width: 30vw;
  border-radius: 20px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.25);
  height: auto;
  padding: 20px;
  margin: 0 auto;
}

.login {
  font-size: 30px;
  font-weight: 600;
  letter-spacing: 0px;
  /* line-height: 0px; */
  color: rgba(51, 51, 51, 1);
  text-align: left;
  vertical-align: top;
}

.account {
  opacity: 1;
  border-radius: 8px;
  border: 1px solid rgb(240, 240, 240);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 10px;
  margin-bottom: 20px;
}

.account img {
  width: 18px;
  height: 18px;
}

.footer {
  display: flex;
  justify-content: space-between;
}

.footer .login {
  width: 100%;
  border-radius: 8px;
  background: rgba(51, 72, 247, 1);
  border: none;
  text-align: center;
  color: white;
  font-size: 16px;
  padding: 10px;
}

.footer .regist {
  width: 46%;
  border-radius: 8px;
  background: rgba(186, 186, 186, 1);
  border: none;
  text-align: center;
  color: white;
  font-size: 16px;
  padding: 10px;
}

input {
  outline: none;
}

p {
  color: #000 !important;
}

.getcode {
  color: rgba(0, 46, 232, 1);
  width: 20%;
  cursor: pointer;
}

/* <768px */
@media screen and (max-width: 768px) {
  .kuang {
    width: 90vw;
  }
}

/* >=992 and  */
@media screen and (min-width: 992px) {
}

/* >=768px and <1200px */
@media screen and (min-width: 768px) and (max-width: 1200px) {
}

/* >=1200px */
@media screen and (min-width: 1200px) {
}
>>> input[type="text"] {
  color: #000;
  padding: 0 0;
}
>>> .el-select {
  width: 100%;
}
>>> .el-input__suffix {
  /* top: 7px; */
  right: 0;
}
>>> input:focus {
  outline: none;
  border-color: transparent !important;
}
>>> .el-select:hover .el-input__inner {
  border-color: transparent !important;
}
>>> input::placeholder {
  color: rgba(0, 0, 0, 0.4) !important; /* 将颜色替换为你想要的颜色 */
}
</style>
