<template>
  <Layout :footer-style="2">
    <!-- Start Slider Area -->
    <div
      class="demo-slider-area slider-area bg-transparent slider-style-1 white"
    >
      <div class="mb--20" data-aos="fade-up">
        <el-carousel :interval="5000">
          <el-carousel-item v-for="(item, index) in banner" :key="item.id">
            <div @click="banner_detail(item)" class="bannerBox">
              <img :src="item.image" alt="" />
              <p class="swiper">{{ item.title }}</p>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
    <!-- End Slider Area -->

    <Separator />
    <!-- Start Our Demo -->

    <div class="rn-demo-area" id="demo">
      <div class="container">
        <div class="row" style="background-color: white; padding: 30px 0">
          <div class="col-lg-4 col-md-4 col-12 Leftcard" v-if="gaokao[0]">
            <div class="Leftcard_title">{{ gaokao[0].name }}</div>
            <div class="Leftcard_content">
              <p
                class="content_title"
                :style="gaokao[0].sub[0].style_text"
                v-if="gaokao[0].sub[0]"
                @click="godetail(gaokao[0].sub[0].id)"
              >
                {{ gaokao[0].sub[0].title }}
              </p>
              <p
                class=""
                :style="gaokao[0].sub[1].style_text"
                v-if="gaokao[0].sub[1]"
                @click="godetail(gaokao[0].sub[1].id)"
              >
                {{ gaokao[0].sub[1].title }}
              </p>
              <p
                v-for="(v, i) in gaokao[0].sub"
                v-if="i > 1"
                @click="godetail(v.id)"
                :key="v.id"
              >
                {{ v.title }}
              </p>
            </div>
          </div>
          <div
            class="col-lg-4 col-md-4 col-12 consult"
            v-for="(item, index) in gaokao"
            v-if="index > 0"
            :key="item.id"
          >
            <div class="consult_title">
              <p class="consult_title_">{{ item.name }}</p>
              <p class="lookmore" @click="lookmore(item)">查看更多</p>
            </div>
            <div
              class="new_list"
              v-for="(v, i) in item.sub"
              @click="godetail(v.id)"
              :key="v.id"
            >
              <p class="new_title" style="width: 75%">
                {{ v.title }}
              </p>
              <p class="new_date">
                {{ v.createtime_text }}
              </p>
            </div>
          </div>
          <div class="col-lg-4 col-md-4 col-12 consult" v-if="is_show_peixun == 1">
            <div class="consult_title">
              <p class="consult_title_">导师推荐</p>
              <p class="lookmore" @click="lookmoreTwo(peixun,peixun_model_id)">查看更多</p>
            </div>
            <div
              class="new_list"
              v-for="(v, i) in peixunList.data"
              @click="godetail(v.id)"
              :key="v.id"
            >
              <p class="new_title" style="width: 75%">
                {{ v.title }}
              </p>
              <p class="new_date">
                {{ v.createtime_text }}
              </p>
            </div>
          </div>
          <div class="col-lg-4 col-md-4 col-12 consult" v-if="is_show_xinwen == 1">
            <div class="consult_title">
              <p class="consult_title_">院内新闻</p>
              <p class="lookmore" @click="lookmoreTwo(xinwen,xinwen_model_id)">查看更多</p>
            </div>
            <div
              class="new_list"
              v-for="(v, i) in xinwenList.data"
              @click="godetail(v.id)"
              :key="v.id"
            >
              <p class="new_title" style="width: 75%">
                {{ v.title }}
              </p>
              <p class="new_date">
                {{ v.createtime_text }}
              </p>
            </div>
          </div>
          <div class="col-lg-12 col-md-12 col-12 joinIn" @click="change_(7, 1)">
            <!-- 进入高考内参频道 -->
          </div>
        </div>
      </div>
    </div>

    <!-- End Our Demo -->
    <!-- <div class="container">
        <div class="row mt--40">
          <img
            src="../../src/assets/images/image.png.png"
            alt=""
            class="col-lg-12 col-md-12 col-12"
          />
        </div>
    </div> -->

    <!-- <div class="rn-demo-area">
            <div class="container">
                <div
                    class="row mt--40"
                    style="background-color: white; padding: 30px 0"
                >
                    <div
                        class="col-lg-8 col-md-12 col-12"
                        v-if="kaoyan && kaoyan.length > 0"
                    >
                        <div class="col-lg-12 news">
                            <p class="newsTiele">{{ kaoyan[0].name }}</p>
                            <p @click="lookmore(kaoyan[0])">查看更多</p>
                        </div>
                        <div class="row">
                            <div
                                class="col-lg-6 newsLeft mt--20"
                                v-if="kaoyan[0].sub[0]"
                                @click="godetail(kaoyan[0].sub[0].id)"
                            >
                                <img
                                    :src="kaoyan[0].sub[0].image"
                                    alt=""
                                    width=""
                                />
                                <div class="title">
                                    {{ kaoyan[0].sub[0].title }}
                                </div>
                                <div
                                    class="tip"
                                    v-html="kaoyan[0].sub[0].description"
                                ></div>
                            </div>
                            <div class="col-lg-6 newsLeft mt--20">
                                <div
                                    class="newsList"
                                    v-for="(v, i) in kaoyan[0].sub"
                                    v-if="i > 0"
                                    @click="godetail(v.id)"
                                    :key="v.id"
                                >
                                    <p class="newsTitle">{{ v.title }}</p>
                                    <p class="newsDate">
                                        {{ v.createtime_text }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        class="col-lg-4 col-md-12 col-12"
                        style="margin: 0"
                        v-if="kaoyan && kaoyan.length > 0"
                    >
                        <div class="col-lg-12 news">
                            <p class="newsTiele">{{ kaoyan[1].name }}</p>
                            <p @click="lookmore(kaoyan[1])">查看更多</p>
                        </div>
                        <div class="newsLeft mt--20">
                            <div
                                class="newsList"
                                v-for="(item, index) in kaoyan[1].sub"
                                @click="godetail(item.id)"
                                :key="item.id"
                            >
                                <p>{{ item.title }}</p>
                                <p class="newsDate">
                                    {{ item.createtime_text }}
                                </p>
                            </div>
                        </div>
                    </div>

                    <div
                        class="col-lg-12 col-md-12 col-12 joinIn"
                        @click="change_(12, 1)"
                    >
                        进入考研内参频道
                    </div>
                </div>
            </div>
        </div> -->

    <!-- <div class="rn-demo-area mt--40">
      <div class="container" v-if="kaoyan && kaoyan.length > 0">
        <div class="row Backing" @click="lookmore(xuanke[0])">
          <div class="col-lg-3 col-md-6 col-12">
            <img
              src="../assets/images/icon.png"
              alt=""
              style="width: 30px; height: 30px; box-sizing: content-box"
            />
            选科规划
          </div>
          <div class="col-lg-8 col-md-8 col-12">
           
          </div>
        </div>
        <div class="row" style="background-color: white; padding: 30px 0">
          <div class="col-lg-4 col-md-4 col-12 consult">
            <div class="consult_title">
              <p class="consult_title_">{{ xuanke[0].name }}</p>
              <p class="lookmore" @click="lookmore(xuanke[0])">查看更多</p>
            </div>
            <div class="new_list row" @click="godetail(xuanke[0].sub[0].id)">
              <div class="col-lg-12 col-md-12 col-12">
                {{ xuanke[0].sub[0].title }}
              </div>
              <div class="row">
                <img
                  :src="xuanke[0].sub[0].image"
                  alt=""
                  class="col-lg-6 col-md-6 col-12"
                />
                <div class="col-lg-6 col-md-6 col-12"></div>
              </div>
            </div>
            <div
              class="new_list"
              v-for="(item, index) in xuanke[0].sub"
              @click="godetail(item.id)"
              :key="item.id"
            >
              <p class="new_title">
                {{ item.title }}
              </p>
            </div>
          </div>
          <div
            class="col-lg-4 col-md-4 col-12 consult"
            v-for="(item, index) in xuanke"
            v-if="index > 0"
            :key="item.id"
          >
            <div class="consult_title">
              <p class="consult_title_">{{ item.name }}</p>
              <p class="lookmore" @click="lookmore(item)">查看更多</p>
            </div>
            <div
              class="new_list"
              v-for="(v, i) in item.sub"
              @click="godetail(item.id)"
              :key="v.id"
            >
              <p class="new_title">
                {{ v.title }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div> -->

    <!-- <div class="rn-demo-area mt--40">
      <div class="container">
        <div class="row Backing">
          <div class="col-lg-3 col-md-6 col-12"
          v-if="zonghe && zonghe.length > 0">
            <img
              src="../assets/images/icon.png"
              alt=""
              style="width: 30px; height: 30px; box-sizing: content-box"
            />
            {{ zonghe[0].name }}
          </div>
          <div class="col-lg-8 col-md-8 col-12">
        
          </div>
        </div>
        <div
          class="row"
          style="background-color: white; padding: 30px 0"
          v-if="zonghe && zonghe.length > 0"
        >
          <div class="col-lg-4 col-md-4 col-12 consult">
            <div class="consult_title">
              <p class="consult_title_">{{ zonghe[0].name }}</p>
              <p class="lookmore" @click="lookmore(zonghe[0])">查看更多</p>
            </div>
            <div
              class="new_list row"
              v-if="zonghe[0].sub[0]"
              @click="godetail(zonghe[0].sub[0].id)"
            >
              <div class="col-lg-12 col-md-12 col-12">
                {{ zonghe[0].sub[0].title }}
              </div>
              <div class="row">
                <img
                  :src="zonghe[0].sub[0].image"
                  alt=""
                  class="col-lg-6 col-md-6 col-12"
                />
                <div class="col-lg-6 col-md-6 col-12">
                  为方便各位家长及考生提前定位，确定综合评价目标院校...
                </div>
              </div>
            </div>
            <div
              class="new_list"
              v-for="(item, index) in zonghe[0].sub"
              @click="godetail(item.id)"
              :key="item.id"
            >
              <p class="new_title">
                {{ item.title }}
              </p>
            </div>
          </div>

          <div
            class="col-lg-4 col-md-4 col-12 consult"
            v-for="(item, index) in zonghe"
            v-if="index > 0"
            :key="item.id"
          >
            <div class="consult_title">
              <p class="consult_title_">{{ item.name }}</p>
              <p class="lookmore" @click="lookmore(item)">查看更多</p>
            </div>
            <div
              class="new_list"
              v-for="(v, i) in item.sub"
              @click="godetail(v.id)"
              :key="v.id"
            >
              <p class="new_title">
                {{ v.title }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div> -->

    <div class="rn-demo-area mt--40">
      <div class="container">
        <div class="row mt--40">
          <div
            class="col-lg-8 col-md-12 col-12"
            style="background-color: white; margin: 0 5px 5px 5px"
          >
            <div class="row">
              <!-- <div class="col-lg-6 col-md-6 col-12">
                <img src="../assets/images/banner.png" alt="" />
              </div> -->
              <div class="col-lg-8 col-md-12 col-12">
                <!-- hangye -->
                <div
                  class="meaasge"
                  v-for="(item, index) in hangye"
                  @click="godetail(item.id)"
                  :key="item.id"
                >
                  <div class="meaasge_date">
                    <p class="day">
                      {{ item.createtime_text.slice(8, 10) }}
                    </p>
                    <p class="month">
                      {{ item.createtime_text.slice(0, 7) }}
                    </p>
                  </div>
                  <p
                    class=""
                    style="
                      width: 80%;
                      color: black;
                      margin: 0;
                      overflow: hidden;
                      white-space: nowrap;
                      text-overflow: ellipsis;
                    "
                  >
                    {{ item.title }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-lg-4 col-md-12 col-12"
            style="background-color: white; margin: 0 5px 5px 5px"
          >
            <!-- <div class="row Backing" style="margin-bottom: 15px">
          
            </div> -->
            <div
              class="list"
              v-for="(item, index) in shuju"
              @click="godetail(item.id)"
              :key="item.id"
            >
              {{ item.title }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 视频课程 -->
    <div class="rn-demo-area mt--40">
      <div class="container">
        <div class="row">
          <div class="col-lg-12 col-md-12 col-12 vedio">
            <p>
              <!-- 视频课程 -->
            </p>
            <p style="font-size: 15px" @click="lookmore_vedio(shipin)">
              <!-- 查看更多 -->
            </p>
          </div>
        </div>
        <div class="row">
          <div
            class="col-lg-3 col-md-12 col-12"
            v-for="(item, index) in shipin"
            @click="govideo(item.id)"
            :key="item.id"
          >
            <img :src="item.image" alt="" />
            <div class="card_white">
              <p class="name">{{ item.name }}</p>
              <div class="flex">
                <p>
                  课程价格：<span class="red">{{ item.price }}</span>
                </p>
                <p>学习人数：{{ item.buy_num }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 强基计划 -->
    <div class="rn-demo-area mt--40" v-if="qiangji && qiangji.length > 0">
      <div class="container">
        <div class="row Backing" @click="lookmore(qiangji[0])">
          <div class="col-lg-3 col-md-6 col-12">
            <img
              src="../assets/images/icon.png"
              alt=""
              style="width: 30px; height: 30px; box-sizing: content-box"
            />
            强基计划
          </div>
          <div class="col-lg-8 col-md-8 col-12">
            ● &ensp; 热门专业 &ensp; ● &ensp; 经验分享 &ensp; ● &ensp;
            强基回答&ensp; ●
          </div>
        </div>
        <div class="row" style="background-color: white; padding: 30px 0">
          <div class="col-lg-4 col-md-4 col-12 consult">
            <div class="consult_title">
              <p class="consult_title_">{{ qiangji[0].name }}</p>
              <p class="lookmore" @click="lookmore(qiangji[0])">查看更多</p>
            </div>
            <div class="new_list row" @click="godetail(qiangji[0].sub[0].id)">
              <div class="col-lg-12 col-md-12 col-12">
                {{ qiangji[0].sub[0].title }}
              </div>
              <div class="row">
                <img
                  :src="qiangji[0].sub[0].image"
                  alt=""
                  class="col-lg-6 col-md-6 col-12"
                />
                <div class="col-lg-6 col-md-6 col-12">
                  为方便各位家长及考生提前定位，确定综合评价目标院校...
                </div>
              </div>
            </div>
            <div
              class="new_list"
              v-for="(item, index) in qiangji[0].sub"
              @click="godetail(item.id)"
              v-if="index > 0"
              :key="item.id"
            >
              <p class="new_title">
                {{ item.title }}
              </p>
            </div>
          </div>
          <div
            class="col-lg-4 col-md-4 col-12 consult"
            v-for="(item, index) in qiangji"
            v-if="index > 0"
            :key="item.id"
          >
            <div class="consult_title">
              <p class="consult_title_">{{ item.name }}</p>
              <p class="lookmore" @click="lookmore(item)">查看更多</p>
            </div>
            <div
              class="new_list"
              v-for="(v, i) in item.sub"
              @click="godetail(item.id)"
              :key="v.id"
            >
              <p class="new_title">
                {{ v.title }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 军校警校 -->
    <div class="rn-demo-area mt--40" v-if="junxiao && junxiao.length > 0">
      <div class="container">
        <div class="row Backing" @click="lookmore(junxiao[0])">
          <div class="col-lg-3 col-md-6 col-12">
            <img
              src="../assets/images/icon.png"
              alt=""
              style="width: 30px; height: 30px; box-sizing: content-box"
            />
            军校警校
          </div>
          <div class="col-lg-8 col-md-8 col-12">
            ● &ensp; 热门专业 &ensp; ● &ensp; 经验分享 &ensp; ● &ensp;
            强基回答&ensp; ●
          </div>
        </div>
        <div class="row" style="background-color: white; padding: 30px 0">
          <div class="col-lg-4 col-md-4 col-12 consult">
            <div class="consult_title">
              <p class="consult_title_">{{ junxiao[0].name }}</p>
              <p class="lookmore" @click="lookmore(junxiao[0])">查看更多</p>
            </div>
            <div class="new_list row" @click="godetail(junxiao[0].sub[0].id)">
              <div class="col-lg-12 col-md-12 col-12">
                {{ junxiao[0].sub[0].title }}
              </div>
              <div class="row">
                <img
                  :src="junxiao[0].sub[0].image"
                  alt=""
                  class="col-lg-6 col-md-6 col-12"
                />
                <div class="col-lg-6 col-md-6 col-12"></div>
              </div>
            </div>
            <div
              class="new_list"
              v-for="(item, index) in junxiao[0].sub"
              @click="godetail(item.id)"
              v-if="index > 0"
              :key="item.id"
            >
              <p class="new_title">
                {{ item.title }}
              </p>
            </div>
          </div>
          <div
            class="col-lg-4 col-md-4 col-12 consult"
            v-for="(item, index) in junxiao"
            v-if="index > 0"
            :key="item.id"
          >
            <div class="consult_title">
              <p class="consult_title_">{{ item.name }}</p>
              <p class="lookmore" @click="lookmore(item)">查看更多</p>
            </div>
            <div
              class="new_list"
              v-for="(v, i) in item.sub"
              @click="godetail(item.id)"
              :key="v.id"
            >
              <p class="new_title">
                {{ v.title }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 专项计划 -->
    <div class="rn-demo-area mt--40" v-if="zhuanxiang && zhuanxiang.length > 0">
      <div class="container">
        <div class="row Backing" @click="lookmore(zhuanxiang[0])">
          <div class="col-lg-3 col-md-6 col-12">
            <img
              src="../assets/images/icon.png"
              alt=""
              style="width: 30px; height: 30px; box-sizing: content-box"
            />
            专项计划
          </div>
          <div class="col-lg-8 col-md-8 col-12">
            ● &ensp; 热门专业 &ensp; ● &ensp; 经验分享 &ensp; ● &ensp;
            强基回答&ensp; ●
          </div>
        </div>
        <div class="row" style="background-color: white; padding: 30px 0">
          <div class="col-lg-4 col-md-4 col-12 consult">
            <div class="consult_title">
              <p class="consult_title_">
                {{ zhuanxiang[0].name }}
              </p>
              <p class="lookmore" @click="lookmore(zhuanxiang[0])">查看更多</p>
            </div>
            <div
              v-if="zhuanxiang[0].sub[0]"
              class="new_list row"
              @click="godetail(zhuanxiang[0].sub[0].id)"
            >
              <div class="col-lg-12 col-md-12 col-12">
                {{ zhuanxiang[0].sub[0].title }}
              </div>

              <div class="row">
                <img
                  :src="zhuanxiang[0].sub[0].image"
                  alt=""
                  class="col-lg-6 col-md-6 col-12"
                />
                <div class="col-lg-6 col-md-6 col-12"></div>
              </div>
            </div>
            <div
              class="new_list"
              v-for="(item, index) in zhuanxiang[0].sub"
              @click="godetail(item.id)"
              v-if="index > 0"
              :key="item.id"
            >
              <p class="new_title">
                {{ item.title }}
              </p>
            </div>
          </div>
          <div
            class="col-lg-4 col-md-4 col-12 consult"
            v-for="(item, index) in zhuanxiang"
            v-if="index > 0"
            :key="item.id"
          >
            <div class="consult_title">
              <p class="consult_title_">{{ item.name }}</p>
              <p class="lookmore" @click="lookmore(item)">查看更多</p>
            </div>
            <div
              class="new_list"
              v-for="(v, i) in item.sub"
              @click="godetail(item.id)"
              :key="v.id"
            >
              <p class="new_title">
                {{ v.title }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 培训基地 -->

    <!-- End Inner Pages -->
  </Layout>
</template>

<script>
import Layout from "../components/common/Layout";
import Icon from "../components/icon/Icon";
import Separator from "../components/elements/separator/Separator";
import SectionTitle from "../components/elements/sectionTitle/SectionTitle";
import carousel from "vue-owl-carousel"; //猫头鹰传送带
import Swiper from "swiper"; // 注意引入的是Swiper
import "swiper/css/swiper.min.css"; // 注意这里的引入

export default {
  name: "Demo",
  components: {
    Icon,
    Layout,
    Separator,
    SectionTitle,
    carousel,
  },
  data() {
    return {
      gaokao: [],
      kaoyan: [],
      xuanke: [],
      zonghe: [],
      hangye: [],
      shipin: [],
      shuju: [],
      qiangji: [],
      junxiao: [],
      zhuanxiang: [],
      img: [],
      partner: [],
      banner: [],
      is_show_peixun: 0,
      is_show_xinwen: 0,
      peixun: "",
      xinwen: "",
      peixunList: [],
      xinwenList: [],
      xinwen_model_id: 1,
      peixun_model_id:1,
    };
  },
  created() {
    this.getData();
    this.bannerShow();
  },
  methods: {
    banner_detail(item) {
      if (item.archive_id == 0) {
        return;
      }
      this.$router.push({
        path: `/detail?id=${item.archive_id}`,
      });
    },
    bannerShow() {
      this.$http
        .get(
          "https://cyfyjiaoxue.cn//api/index/getBannerList",
          {
            params: {
              source_channel_id: 0,
            },
          },
          {
            emulateJSON: true,
          }
        )
        .then(
          function (res) {
            if (res.data.code == 200) {
              this.banner = res.data.data;
            } else if (res.data.code == 114) {
              this.$alert(res.data.msg, "提示", {
                confirmButtonText: "确定",
                callback: (action) => {
                  this.$router.push({
                    path: `/login`,
                  });
                },
              });
            }
          },
          function (res) {
            console.log(res); // 响应错误回调
          }
        );
    },
    change_(id, model_id) {
      if (model_id == 1) {
        const { href } = this.$router.resolve({
          path: "/about",
          query: {
            id: id,
            model_id: model_id,
          },
        });
        window.open(href);
        //关闭窗口的方法
        window.opener = null;
        window.open("", "_self", "");
        window.close();
      } else if (model_id == 3) {
        if (name == "商品橱窗") {
          const { href } = this.$router.resolve({
            path: "/shop",
            query: {
              id: id,
              model_id: model_id,
            },
          });
          window.open(href);
          //关闭窗口的方法
          window.opener = null;
          window.open("", "_self", "");
          window.close();
        } else {
          const { href } = this.$router.resolve({
            path: "/zldetail",
            query: {
              id: id,
              model_id: model_id,
            },
          });
          window.open(href);
          //关闭窗口的方法
          window.opener = null;
          window.open("", "_self", "");
          window.close();
        }
      } else if (model_id == 2) {
        const { href } = this.$router.resolve({
          path: "/video",
          query: {
            id: id,
            model_id: model_id,
          },
        });
        window.open(href);
        //关闭窗口的方法
        window.opener = null;
        window.open("", "_self", "");
        window.close();
      }
    },
    change_url(url) {
      window.open(url);
    },
    lookmore_vedio(item) {
      console.log(item);
      this.$router.push({
        path: `/video?id=${item[0].id}&model_id=2`,
      });
    },
    lookmore(item) {
      console.log(item);
      this.$router.push({
        path: `/about?id=${item.id}&model_id=1`,
      });
    },
   
    lookmoreTwo(id,model_id){
      this.$router.push({
        path: `/about?id=${item.id}&model_id=1`,
      });
    },

    govideo(id) {
      this.$router.push({
        path: `/videoDeatil?channel_id=${id}`,
      });
    },
    godetail(id) {
      this.$router.push({
        path: `/detail?id=${id}`,
      });
    },
    // 新闻信息
    xinwenfun() {
      this.$http
        .get(
          "https://cyfyjiaoxue.cn//api/index/navJump",
          {
            params: {
              id: this.xinwen,
              model_id: this.xinwen_model_id,
              page: 1,
              row: 10,
              region_id: 0,
            },
          },
          {
            emulateJSON: true,
          }
        )
        .then(
          function (res) {
            if (res.data.code == 200) {
              console.log("object :>> ", res.data.data);
              this.xinwenList = res.data.data.list.data;
            } else if (res.data.code == 114) {
              this.$alert(res.data.msg, "提示", {
                confirmButtonText: "确定",
                callback: (action) => {
                  this.$router.push({
                    path: `/login`,
                  });
                },
              });
            }
          },
          function (res) {
            console.log(res); // 响应错误回调
          }
        );
    },
    //导师推荐
    peixunfun() {
      this.$http
        .get(
          "https://cyfyjiaoxue.cn//api/index/navJump",
          {
            params: {
              id: this.peixun,
              model_id: this.peixun_model_id,
              page: 1,
              row: 10,
              region_id: 0,
            },
          },
          {
            emulateJSON: true,
          }
        )
        .then(
          function (res) {
            if (res.data.code == 200) {
              this.peixunList = res.data.data.list;
            } else if (res.data.code == 114) {
              this.$alert(res.data.msg, "提示", {
                confirmButtonText: "确定",
                callback: (action) => {
                  this.$router.push({
                    path: `/login`,
                  });
                },
              });
            }
          },
          function (res) {
            console.log(res); // 响应错误回调
          }
        );
    },
    // 首页信息
    getData() {
      this.$http.get("https://cyfyjiaoxue.cn//api/index/index").then(
        function (res) {
          if (res.body.code == 200) {
            this.peixun = res.body.data.peixun;
            this.xinwen = res.body.data.xinwen;
            this.is_show_peixun = res.body.data.is_show_peixun; 
            this.is_show_xinwen = res.body.data.is_show_xinwen;
            this.xinwen_model_id = res.body.data.xinwen_model_id ? res.body.data.xinwen_model_id : 1;
            this.peixun_model_id = res.body.data.peixun_model_id ? res.body.data.peixun_model_id : 1;
            this.gaokao = res.body.data.gaokao;
            this.kaoyan = res.body.data.kaoyan;
            this.xuanke = res.body.data.xuanke;
            this.zonghe = res.body.data.zonghe;
            this.hangye = res.body.data.hangye;
            this.shipin = res.body.data.shipin;
            this.shuju = res.body.data.shuju;
            this.qiangji = res.body.data.qiangji;
            this.junxiao = res.body.data.junxiao;
            this.zhuanxiang = res.body.data.zhuanxiang;
            this.partner = res.body.data.partner;
            this.xinwenfun();
            this.peixunfun();
          } else if (res.body.code == 114) {
            this.$alert(res.body.msg, "提示", {
              confirmButtonText: "确定",
              callback: (action) => {
                this.$router.push({
                  path: `/login`,
                });
              },
            });
          }
        },
        function (res) {
          console.log(res); // 响应错误回调
        }
      );
    },
  },
};
</script>
<style>
.bannerBox {
  position: relative;
  height: 100%;
}
.bannerBox img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
body {
  background-color: rgba(245, 245, 245, 1) !important;
}

.white {
  background-color: white !important;
}

.Leftcard {
  color: #000;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.25);
  padding: 0 !important;
  box-sizing: border-box !important;
}

.Leftcard_title {
  color: #fff;
  background: rgba(97, 171, 255, 1);
  width: 100%;
  font-size: 20px;
  padding: 5px 15px;
  margin-bottom: 10px;
}

.Leftcard_content {
  padding: 5px 15px;
}

.Leftcard_content p {
  color: #000;
  margin: 0;
  line-height: 35px;
  font-size: 16px;
}

.red {
  color: red !important;
}

.content_title {
  font-size: 17px;
}

.consult {
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 1.71px 5.12px 0px rgba(0, 0, 0, 0.25);
  padding: 0 !important;
  box-sizing: border-box !important;
}

.consult_title {
  display: flex;
  border-bottom: 0.85px solid rgba(224, 224, 224, 1);
  color: #000;
  justify-content: space-between;
  align-items: flex-end;
  padding: 0px 15px;
  padding-top: 6px;
  margin-bottom: 10px;
}

.consult_title p {
  color: #000;
  margin: 0 !important;
}

.consult_title_ {
  border-bottom: 10px solid rgba(97, 171, 255, 1);
}

.lookmore {
  font-size: 14px;
  border-bottom: 5px solid white;
}

.new_list {
  display: flex;
  border-bottom: 0.85px solid rgba(224, 224, 224, 1);
  color: #000;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
}

.new_list p {
  margin: 0 !important;
  color: #000;
  font-size: 16px;
  cursor: pointer;
}

.new_list p:hover {
  text-decoration: underline;
  color: rgb(97, 171, 255) !important;
}

.new_date {
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 0px;
  color: rgba(97, 171, 255, 1) !important;
  text-align: left;
  vertical-align: top;
}

.new_title {
  width: 95%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

@media (min-width: 992px) {
  .col-lg-4 {
    flex: 0 0 auto;
    width: 31.5% !important;
    margin: 10px;
  }
}

.joinIn {
  color: rgba(101, 103, 112, 1);
  text-align: right;
  padding: 0 25px !important;
}

.news {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: center;
  border-bottom: 8px solid rgba(97, 171, 255, 1);
  border-radius: 5px;
  height: 50px;
}

.news p {
  color: #000;
  margin: 0;
}

.newsTiele {
  font-size: 20.47px;
  font-weight: 550;
  color: rgba(0, 0, 0, 1);
  text-align: justify;
  vertical-align: top;
}

.newsLeft {
  text-align: center;
}

.newsLeft .title {
  font-size: 20.47px;
  font-weight: 400;
  letter-spacing: 0px;
  color: rgba(0, 0, 0, 1);
  text-align: justify;
  vertical-align: top;
  text-align: center;
  margin: 10px 0 20px;
}

.newsLeft .tip {
  font-size: 17.06px;
  font-weight: 400;
  letter-spacing: 0px;
  color: rgba(145, 145, 145, 1);
  text-align: justify;
  vertical-align: top;
}

.newsList .new_date {
  font-size: 15.35px;
  font-weight: 400;
  letter-spacing: 0px;
  color: rgba(97, 97, 97, 1) !important;
  text-align: justify;
  vertical-align: top;
}

.newsList {
  border-bottom: 0.85px solid rgba(166, 166, 166, 1);
  padding: 20px 0 5px;
}

.newsList p {
  color: #000;
  margin: 0;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.Backing {
  background-color: rgba(97, 171, 255, 1);
  color: #fff;
  padding: 10px 10px;
  font-size: 20px;
  height: 50px;
}

.Backing img {
  width: 44px;
  height: 30px;
}

.meaasge {
  display: flex;
  margin: 15px 0;
}

.meaasge p:hover {
  text-decoration: underline;
  color: rgb(97, 171, 255) !important;
}

.meaasge .meaasge_date {
  color: rgba(97, 97, 97, 1);
  background: rgba(204, 204, 204, 1);
  width: 50px;
  height: 50px;
  opacity: 1;
  background: rgba(204, 204, 204, 1);
  border-radius: 9px;
  margin-right: 15px;
}

.meaasge .meaasge_date p {
  margin: 0 auto;
  text-align: center;
  color: rgba(97, 97, 97, 1);
  height: 25px;
}

.meaasge .meaasge_date .day {
  font-size: 15px;
}

.meaasge .meaasge_date .month {
  font-size: 6px;
}

.list {
  color: #000;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0px;
  color: rgba(97, 97, 97, 1);
  text-align: justify;
  vertical-align: top;
  line-height: 40px;
}

.list:hover {
  text-decoration: underline;
  color: rgb(97, 171, 255) !important;
}

.vedio {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.vedio p {
  margin: 0;
  color: #000;
  font-size: 25px;
  font-weight: 500;
  letter-spacing: 0px;
  color: rgba(0, 0, 0, 1);
  text-align: left;
  vertical-align: top;
}

.card_white {
  padding: 13px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 1.71px 3.41px 0px rgba(0, 0, 0, 0.25);
  margin-bottom: 20px;
}

.card_white .flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 13.65px;
  font-weight: 400;
  letter-spacing: 0px;
  color: rgba(87, 87, 87, 1);
  text-align: left;
  vertical-align: top;
}

span.red {
  color: rgba(97, 171, 255, 1);
}

.card_white .name {
  font-size: 17.06px;
  font-weight: 400;
  letter-spacing: 0px;
  color: rgba(0, 0, 0, 1);
  text-align: left;
  vertical-align: top;
  margin-bottom: 20px;
}

.card_white p {
  margin: 0;
  color: #000;
}

.outer-container {
  position: relative;
  overflow: hidden;
}

.inner-containe {
  display: flex;
  width: 100%;
  margin-bottom: 20px;
  box-sizing: border-box;
  overflow-x: scroll;
  /* 设置溢出滚动 */
  white-space: nowrap;
  overflow-y: hidden;
  /* 隐藏滚动条 */
  scrollbar-width: none;
  /* firefox */
  -ms-overflow-style: none;
  /* IE 10+ */
}

.inner-containe::-webkit-scrollbar {
  display: none;
  /* Chrome Safari */
}

@media screen and (max-width: 768px) {
  .el-carousel__container {
    position: relative;
    height: 188px !important;
  }

  .swiper {
    bottom: -20px;
  }
}

/* >=992 and  */
@media screen and (min-width: 992px) {
}

/* >=768px and <1200px */
@media screen and (min-width: 768px) and (max-width: 1200px) {
}

/* >=1200px */
@media screen and (min-width: 1200px) {
  .el-carousel__container {
    position: relative;
    height: 350px !important;
  }

  .owl-carousel.owl-drag .owl-item {
    width: 13vw !important;
  }
}

.swiper {
  position: absolute;
  color: white;
  font-size: 16px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  bottom: -10px;
  z-index: 99;
  padding: 10px 17% !important;
}

.el-carousel__indicators {
  position: absolute;
  list-style: none;
  margin: 0;
  padding: 0;
  z-index: 2;
  right: 0;
  display: flex;
  justify-content: flex-end;
}

.el-carousel__button {
  display: block;
  opacity: 0.48;
  width: 15px !important;
  height: 15px !important;
  background-color: #fff;
  border: none;
  outline: 0;
  padding: 0;
  margin: 0;
  cursor: pointer;
  transition: 0.3s;
}

.el-carousel__indicator--horizontal {
  display: inline-block;
  padding: 5px 4px !important;
}

.newsTitle:hover {
  cursor: pointer;
  text-decoration: underline;
  color: rgb(97, 171, 255) !important;
}
</style>
