<template>
  <div class="popup-mobile-menu">
    <div class="inner">
      <div class="header-top">
        <div class="logo">
          <router-link to="/">
            <img
              class="logo-light"
              :src="logo"
              alt="Corporate Logo"
            />
            <img
              class="logo-dark"
              :src="logo"
              alt="Corporate Logo"
            />
          </router-link>
        </div>
        <div class="close-menu">
          <button
            class="close-button"
            @click.prevent="
              AppFunctions.toggleClass('.popup-mobile-menu', 'active')
            "
          >
            <Icon name="x" size="21" />
          </button>
        </div>
      </div>
      <ul class="mainmenu">
        <li class="has-droupdown has-menu-child-item">
          <a href="/"> 首页 </a>
          <ul class="submenu"></ul>
        </li>

        <li
          class="has-droupdown has-menu-child-item"
          v-for="(item, index) in nav"
        >
          <a href="#" @click="change(index)">
            {{ item.name }}
            <Icon name="plus" class="collapse-icon plus" />
            <Icon name="minus" class="collapse-icon minus" />
          </a>
          <ul class="submenu" :class="item.show ? 'active' : ''">
            <li v-for="(v, i) in item.sub" @click="detail(v)">
              {{ v.name }}
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import Nav from "./Nav";
import Icon from "../../icon/Icon";
import AppFunctions from "../../../helpers/AppFunctions";
export default {
  name: "MobileMenu",
  components: {
    Nav,
    Icon,
  },
  props: {
    logo: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      AppFunctions,
      nav: [],
    };
  },
  created() {
    this.getData();
  },
  mounted() {
    let elements = document.querySelectorAll(
        ".popup-mobile-menu .has-droupdown > a"
      ),
      elementsTwo = document.querySelectorAll(
        ".popup-mobile-menu .with-megamenu > a"
      );
    for (let i in elements) {
      if (elements.hasOwnProperty(i)) {
        elements[i].onclick = function () {
          this.parentElement
            .querySelector(".submenu")
            .classList.toggle("active");
          this.classList.toggle("open");
        };
      }
    }

    for (let i in elementsTwo) {
      if (elementsTwo.hasOwnProperty(i)) {
        elementsTwo[i].onclick = function () {
          this.parentElement
            .querySelector(".rn-megamenu")
            .classList.toggle("active");
          this.classList.toggle("open");
        };
      }
    }
  },
  methods: {
    // 首页信息
    getData() {
      this.$http.get("https://cyfyjiaoxue.cn//api/index/index").then(
        function (res) {
          let data = res.body.data.nav;
          data.forEach(function (item, index) {
            item.show = false;
          });
          this.nav = data;
        },
        function (res) {
          console.log(res); // 响应错误回调
        }
      );
    },
    change(index) {
      this.nav[index].show
        ? (this.nav[index].show = false)
        : (this.nav[index].show = true);
    },
    detail(item) {
      if (item.model_id == 1) {
        const { href } = this.$router.resolve({
          path: "/about",
          query: {
            id: item.id,
            model_id: item.model_id,
          },
        });
        window.open(href);
        //关闭窗口的方法
        window.opener = null;
        window.open("", "_self", "");
        window.close();
      } else if (item.model_id == 3) {
        if (item.name == "商品橱窗") {
          const { href } = this.$router.resolve({
            path: "/shop",
            query: {
              id: item.id,
              model_id: item.model_id,
            },
          });
          window.open(href);
          //关闭窗口的方法
          window.opener = null;
          window.open("", "_self", "");
          window.close();
        } else {
          const { href } = this.$router.resolve({
            path: "/zldetail",
            query: {
              id: item.id,
              model_id: item.model_id,
            },
          });
          window.open(href);
          //关闭窗口的方法
          window.opener = null;
          window.open("", "_self", "");
          window.close();
        }
      } else if (item.model_id == 2) {
        const { href } = this.$router.resolve({
          path: "/video",
          query: {
            id: item.id,
            model_id: item.model_id,
          },
        });
        window.open(href);
        //关闭窗口的方法
        window.opener = null;
        window.open("", "_self", "");
        window.close();
      }
    },
  },
};
</script>
<style>
@media screen and (max-width: 768px) {
  .has-menu-child-item::before {
    content: "";
    box-sizing: border-box;
    display: flex;
    align-items: center;
    color: rgba(204, 204, 204, 1);
  }

  .has-menu-child-item {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    width: 100%;
  }

  .popup-mobile-menu .mainmenu .has-droupdown .submenu.active {
    height: 100%;
    opacity: 1;
    visibility: visible;
    padding-bottom: 12px;
  }

  .popup-mobile-menu .mainmenu .has-droupdown .submenu {
    height: 0;
    transition: all 0.3s;
    opacity: 0;
    visibility: hidden;
    padding: 0;
    max-width: 100%;
    list-style: none;
    padding-left: 14px;
  }

  .popup-mobile-menu .mainmenu .has-menu-child-item > a .collapse-icon {
    position: absolute;
    right: -51vw;
    top: 50%;
    display: block;
    transform: translateY(-50%);
    transition: 0.4s;
  }
}
</style>
