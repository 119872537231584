<template>
  <div :class="{ 'header-transparent-with-topbar': data.headerTransparency }">
    <div
      v-if="data.showTopHeader && data.topbarStyle === 2"
      class="header-top-bar"
    >
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-4 col-md-12 col-12">
            <div class="header-left">
              <p>
                <a href="#"
                  >Get the most advanced template
                  <Icon name="chevron-right" />
                </a>
              </p>
            </div>
          </div>
          <div class="col-lg-8 col-md-12 col-12">
            <div class="header-right">
              <div class="address-content">
                <p><Icon name="map-pin" /><span>Alabama, USA</span></p>
                <p>
                  <Icon name="phone" /><span
                    ><a href="#">+06 58 49 99 56</a></span
                  >
                </p>
              </div>
              <div class="social-icon-wrapper">
                <ul class="social-icon social-default icon-naked">
                  <li>
                    <a target="_blank" href="https://www.facebook.com/">
                      <Icon name="facebook" />
                    </a>
                  </li>
                  <li>
                    <a target="_blank" href="https://www.twitter.com">
                      <Icon name="twitter" />
                    </a>
                  </li>
                  <li>
                    <a target="_blank" href="https://www.instagram.com">
                      <Icon name="instagram" />
                    </a>
                  </li>
                  <li>
                    <a target="_blank" href="https://www.linkedin.com">
                      <Icon name="linkedin" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-hidden-sm header_">
      <div class="container" style="margin: 40px auto">
        <div class="row align-items-center">
          <div class="col-lg-4 col-md-4 col-12">
            <img
              :src="website_icon"
              alt=""
              style="height: 60px;"
            />
          </div>
          <div class="col-lg-6 col-md-4 col-12">
            <div
              for=""
              style="
                border: 1px solid rgba(204, 204, 204, 1);
                border-radius: 5px;
                display: flex;
                width: 60%;
              "
            >
              <input
                type="text"
                placeholder="请输入"
                class="search"
                style="color: black"
                v-model="seachKey"
              />
              <p
                style="
                  background-color: rgba(97, 171, 255, 1);
                  margin: 0;
                  padding: 10px;
                "
                @click="search()"
              >
                <img
                  src="../../../assets/images/sarch.png"
                  alt=""
                  style="width: 30px"
                />
              </p>
            </div>
          </div>

          <div
            class="col-lg-2 col-md-4 col-12"
            style="text-align: right; display: flex"
            v-if="login == false"
          >
            <router-link to="/login" class="login_btn blue" style="color: #fff">
              登录
            </router-link>
            <router-link to="/register" class="login_btn blue">
              注册
            </router-link>
          </div>
          <div
            class="col-lg-2 col-md-4 col-12"
            style="text-align: right; display: flex"
            v-else
          >
            <router-link
              to="/message"
              class="login_btn blue"
              style="color: #fff"
            >
              个人中心
            </router-link>
            <div class="login_btn gray" @click="out">退出</div>
          </div>
        </div>
      </div>
    </div>

    <!-- Start Header Area -->
    <header
      :class="[
        `rn-header header-default header-not-transparent header-sticky ${data.headerClass}`,
      ]"
    >
      <div class="container position-relative">
        <div class="row align-items-center row--0">
          <template v-if="data.navAlignment === 'right'">
            <div class="col-lg-12 col-md-12 col-12 position-static">
              <div class="header-right">
                <nav class="mainmenu-nav d-none d-lg-block" style="width: 100%">
                  <Nav />
                </nav>
                <div class="mobile-menu-bar ml--5 d-block d-lg-none">
                  <div class="hamberger">
                    <button
                      class="hamberger-button"
                      @click.prevent="
                        AppFunctions.toggleClass('.popup-mobile-menu', 'active')
                      "
                    >
                      <Icon name="menu" size="21" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <template v-if="data.navAlignment === 'left'">
            <div class="col-lg-9 col-md-6 col-4 position-static">
              <div class="header-left d-flex">
                <Logo />
                <nav class="mainmenu-nav d-none d-lg-block">
                  <Nav />
                </nav>
              </div>
            </div>
            <div class="col-lg-3 col-md-6 col-8">
              <div class="header-right">
                <!-- Start Header Btn -->
                <div class="header-btn">
                  <a :class="data.buyButtonClass" href="#"> BUY NOW </a>
                </div>
                <!-- End Header Btn  -->

                <!-- Start Mobile-Menu-Bar -->
                <div class="mobile-menu-bar ml--5 d-block d-lg-none">
                  <div class="hamberger">
                    <button
                      class="hamberger-button"
                      @click.prevent="
                        AppFunctions.toggleClass('.popup-mobile-menu', 'active')
                      "
                    >
                      <Icon name="menu" size="21" />
                    </button>
                  </div>
                </div>
                <!-- Start Mobile-Menu-Bar -->

                <!-- <div v-if="data.showThemeSwitcherButton" id="my_switcher" class="my_switcher">
									<ul>
										<li>
											<a href="javascript: void(0);"
												@click.prevent="AppFunctions.toggleClass('body', 'active-light-mode')">
												<img class="light-icon" src="../../../assets/images/icons/sun-01.svg"
													alt="Sun images">
												<img class="dark-icon" src="../../../assets/images/icons/vector.svg"
													alt="Moon Images">
											</a>
										</li>
									</ul>
								</div> -->
              </div>
            </div>
          </template>
        </div>
      </div>
    </header>

    <MobileMenu :logo="website_icon" />

    <div>
      <div class="rn-gradient-circle" />
      <div class="rn-gradient-circle theme-pink" />
    </div>
  </div>
</template>

<script>
import Icon from "../../icon/Icon";
import MobileMenu from "./MobileMenu";
import AppFunctions from "../../../helpers/AppFunctions";
import Nav from "./Nav";
import Logo from "../../elements/logo/Logo";

export default {
  name: "Header",
  props: {
    data: {
      default: null,
      // seachKey: '',
      login: false,
      user: {},
    },
  },
  components: {
    Logo,
    Nav,
    MobileMenu,
    Icon,
  },
  data() {
    return {
      AppFunctions,
      seachKey: "",
      website_icon:""
    };
  },
  methods: {
    IcoCreate(icoUrl) {
      var link =
    document.querySelector("link[rel*='icon']") ||
    document.createElement("link");
      link.type = "image/x-icon";
      link.rel = "icon";
      link.href = icoUrl;
      var head = document.head || document.getElementsByTagName("head")[0];
      head.appendChild(link);
      this.$forceUpdate()
    },
    async apidocApi() {
      await this.$http({
        method: "post",
        url: "https://cyfyjiaoxue.cn//api/index/index",
        headers: {
          token: window.localStorage.getItem("token"),
        },
      })
        .then((res) => {
          //res 存入本地
          window.localStorage.setItem('indexData' , JSON.stringify(res.data.data));
          console.log(res.data);
          this.website_icon = res.data.data.website_icon
          this.IcoCreate(res.data.data.website_icon);
          document.title = res.data.data.name;
        })
        .catch((res) => {});
    },
    out() {
      this.$http({
        method: "post",
        url: "https://cyfyjiaoxue.cn//api/index/logout",
        headers: {
          token: window.localStorage.getItem("token"),
        },
      })
        .then(({ data }) => {
          this.login = false;
          this.$forceUpdate();
        })
        .catch((res) => {
          this.login = false;
          this.$forceUpdate();
          console.log(this.login, "77777777777777777");
        });
    },
    search() {
      if (this.seachKey == "") {
        this.$alert("请填写搜索内容", "提示", {
          confirmButtonText: "确定",
          callback: (action) => {},
        });
        return false;
      }
      const { href } = this.$router.resolve({
        path: "/search",
        query: {
          seachKey: this.seachKey,
        },
      });
      window.open(href);
      //关闭窗口的方法
      window.opener = null;
      window.open("", "_self", "");
      window.close();
    },
    message() {
      this.login = false;
      this.$http({
        method: "get",
        url: "https://cyfyjiaoxue.cn//api/index/userInfo",
        headers: {
          token: window.localStorage.getItem("token"),
        },
      })
        .then(({ data }) => {
          console.log(data, "===============");
          this.login = true;
          this.user = data.data;
          this.$forceUpdate();
          console.log(this.login, "77772222227");
        })
        .catch((res) => {
          this.login = false;
          this.$forceUpdate();
          console.log(this.login, "77777777777777777");
        });
    },
    toggleStickyHeader() {
      const scrolled = document.documentElement.scrollTop;
      if (scrolled > 100) {
        AppFunctions.addClass(".header-default", "sticky");
      } else if (scrolled <= 100) {
        AppFunctions.removeClass(".header-default", "sticky");
      }
    },
  },
  created() {
    console.log("head=================");
    this.message();
  
    window.addEventListener("scroll", this.toggleStickyHeader);
  },
  async mounted() {
    if (window.localStorage.getItem('indexData')) {
    await  this.apidocApi();
      let indexData =JSON.parse( window.localStorage.getItem("indexData"));  
      this.IcoCreate(indexData.website_icon);
      document.title = indexData.name;
      this.website_icon = indexData.website_icon
    }else{
      this.apidocApi();
    }
     this.toggleStickyHeader();
    
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.toggleStickyHeader);
  },
};
</script>
<style>
.login_btn {
  width: 150px;
  padding: 10px 0;
  border-radius: 11.27px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 5px;
  font-size: 14px;
}

.login_btn:hover {
  color: white;
}

.orange {
  background: rgba(255, 74, 74, 1);
}

.blue {
  background-color: rgba(66, 145, 235, 1);
}

.gray {
  background-color: rgba(173, 173, 173, 1);
}

a:hover {
  color: white;
}

/* <768px */
@media screen and (max-width: 768px) {
  .header_ {
    display: none;
  }
}

/* >=992 and  */
@media screen and (min-width: 992px) {
}

/* >=768px and <1200px */
@media screen and (min-width: 768px) and (max-width: 1200px) {
}

/* >=1200px */
@media screen and (min-width: 1200px) {
}
</style>
