<template>
    <Layout header-class="" :show-newsletter="true" :show-cta="false">
        <el-carousel :interval="5000">
            <el-carousel-item v-for="(item, index) in banner" :key="index">
                <div @click="banner_detail(item)">
                    <img
                        :src="item.image"
                        alt
                        style="width: 100%; height: auto; position: relative"
                    />
                    <p class="swiper">{{ item.title }}</p>
                </div>
            </el-carousel-item>
        </el-carousel>

        <div class="line"></div>
        <div class="rn-demo-area" id="demo">
            <div class="container">
                <div class="row" style="">
                    <div class="col-lg-12 col-md-12 col-12 bottom">
                        <div class="title">
                            <p class="title_">{{ categories[0].name }}</p>
                            <!-- <p class="lookmore" @click="lookmore(categories[0])">查看更多</p> -->
                        </div>
                    </div>
                </div>
                <div class="row" v-if="categories[0].list">
                    <div
                        class="col-lg-3 col-md-3 col-12"
                        v-for="(item, index) in categories[0].list"
                        @click="detail(item)"
                        :key="item.id"
                    >
                        <section class="cardWhite">
                            <img :src="item.image" alt="" width="100%" />
                            <div class="cardTitle">
                                <p class="title">{{ item.title }}</p>
                                <p class="desc">{{ item.description }}</p>
                            </div>
                        </section>
                    </div>
                </div>
                <div class="row flex">
                    <div class="col-lg-11 col-md-11 col-11">
                        <div class="center_line"></div>
                    </div>
                    <div
                        class="col-lg-1 col-md-1 col-1"
                        @click="lookmore(categories[0])"
                    >
                        查看更多
                    </div>
                </div>

                <div
                    class="row"
                    style=""
                    v-for="(item, index) in categories"
                    v-if="index > 0"
                    :key="item.id"
                >
                    <div class="col-lg-12 col-md-12 col-12 bottom">
                        <div class="title">
                            <p class="title_">{{ item.name }}</p>
                            <p class="lookmore" @click="lookmore(item)">更多</p>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-3 col-12">
                        <section
                            class="cardWhite"
                            v-if="item.list[0]"
                            @click="detail(item.list[0])"
                        >
                            <img
                                :src="item.list[0].image"
                                alt=""
                                height="400px"
                            />
                            <div class="first_tip">
                                <p class="title">{{ item.list[0].title }}</p>
                                <p class="money">{{ item.price }}</p>
                            </div>
                        </section>
                    </div>
                    <div class="col-lg-9 col-md-12 col-12 row">
                        <div
                            class="col-12"
                            style="
                                margin: 0;
                                margin-bottom: 10px;
                                width: 33% !important;
                            "
                            v-for="(v, i) in item.list"
                            v-if="i > 0"
                            @click="detail(v)"
                            :key="item.id"
                        >
                            <section class="cardWhite">
                                <img :src="v.image" alt="" />
                                <div class="cardTitle">
                                    <p class="title">{{ v.title }}</p>
                                    <p class="desc" style="text-align: right">
                                        {{ v.views }}次学习
                                    </p>
                                </div>
                            </section>
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12 col-12">
                        <div class="line_end"></div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>

<script>
import Layout from "../components/common/Layout";
export default {
    name: "video",
    components: {
        Layout,
    },
    data() {
        return {
            id: "",
            model_id: "",
            page: 1,
            now_id: "",
            now_list: {},
            categories: [],
            banner: [],
        };
    },
    created() {
        this.id = this.$route.query.id;
        this.model_id = this.$route.query.model_id;
        this.list();
        this.bannerShow();
    },
    methods: {
        banner_detail(item) {
            this.$router.push({
                path: `/videoDeatil?id=${item.id}&channel_id=${item.channel_id}`,
            });
        },
        bannerShow() {
            this.$http
                .get(
                    "https://cyfyjiaoxue.cn//api/index/getBannerList",
                    {
                        params: {
                            source_channel_id: this.id,
                        },
                    },
                    {
                        emulateJSON: true,
                    }
                )
                .then(
                    function (res) {
                        if (res.data.code == 200) {
                            this.banner = res.data.data;
                        } else if (res.data.code == 114) {
                            this.$alert(res.data.msg, "提示", {
                                confirmButtonText: "确定",
                                callback: (action) => {
                                    this.$router.push({
                                        path: `/login`,
                                    });
                                },
                            });
                        }
                    },
                    function (res) {
                        console.log(res); // 响应错误回调
                    }
                );
        },
        lookmore(item) {
            console.log(item);
            this.$router.push({
                path: "/videoDeatil",
                query: {
                    channel_id: item.id,
                },
            });
        },
        detail(item) {
            const { href } = this.$router.resolve({
                path: "/videoDeatil",
                query: {
                    id: item.id,
                    channel_id: item.channel_id,
                },
            });
            window.open(href);
            //关闭窗口的方法
            window.opener = null;
            window.open("", "_self", "");
            window.close();
        },
        // 列表
        list() {
            this.$http
                .get(
                    "https://cyfyjiaoxue.cn//api/index/navJump",
                    {
                        params: {
                            id: this.id,
                            model_id: this.model_id,
                            page: this.page,
                            row: 10,
                            region_id: 0,
                        },
                    },
                    {
                        emulateJSON: true,
                    }
                )
                .then(
                    function (res) {
                        if (res.data.code == 200) {
                            this.categories = res.data.data.categories;
                        } else if (res.data.code == 114) {
                            this.$alert(res.data.msg, "提示", {
                                confirmButtonText: "确定",
                                callback: (action) => {
                                    this.$router.push({
                                        path: `/login`,
                                    });
                                },
                            });
                        }
                    },
                    function (res) {
                        console.log(res); // 响应错误回调
                    }
                );
        },
    },
};
</script>
<style scoped>
.white {
    background-color: white;
    padding: 0px 0;
    border-radius: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
}

.white p {
    padding: 5px 10px;
    width: auto;
    border: 1px solid rgba(179, 171, 171, 1);
    border-radius: 5px;
    text-align: center;
    margin: 0 10px;
    margin-bottom: 15px;
}

.carding {
    background-color: white;
    border: none;
    border-radius: 10px;
}

.card_title {
    display: flex;
    justify-content: space-between;
    align-content: center;
    border-bottom: 1px solid rgba(227, 227, 227, 1);
    padding: 10px;
    color: rgba(10, 10, 10, 1);
    margin: 0 10px;
}

.card_content {
    padding: 10px 15px;
    border-bottom: 1px solid rgba(227, 227, 227, 1);
    display: flex;
    justify-content: space-between;
}

.card_content p.title {
    color: rgba(23, 109, 194, 1) !important;
    margin: 0;
    width: 70%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.card_content p.date {
    color: rgba(145, 141, 141, 1) !important;
    margin: 0;
}

.bottom {
    margin-bottom: 15px;
}

.line {
    width: 100%;
    height: 5vh;
}

.title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: black;
    width: 98%;
}

.title p {
    margin: 0;
}

.title .title_ {
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0px;
    color: rgba(0, 0, 0, 1);
    text-align: left;
    vertical-align: top;
}

.title .lookmore {
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0px;
    color: rgba(107, 107, 107, 1);
    text-align: left;
    vertical-align: top;
    border: none;
}

.cardWhite {
    background-color: white;
    margin-bottom: 10px;
}

.cardTitle p {
    margin: 0;
}

.cardTitle {
    padding: 10px;
    min-height: 65px;
}

.cardTitle .title {
    font-size: 16px;
}

.cardTitle .desc {
    font-size: 12px;
    color: rgba(87, 87, 87, 1);
}

.first_tip {
    padding: 15px 10px;
    display: flex;
    justify-content: space-between;
}

.first_tip p {
    margin-bottom: 0;
}

.first_tip .money {
    color: rgba(97, 171, 255, 1);
}
.center_line {
    width: 100%;
    height: 1px;
    background-color: rgba(181, 181, 181, 1);
}
.flex {
    display: flex;
    align-items: center;
    color: rgba(0, 0, 0, 1);
    font-size: 20px;
    /* text-align: right; */
    margin: 20px 0 30px 0;
}
.line_end {
    width: 98%;
    height: 1px;
    background-color: rgba(181, 181, 181, 1);
    margin: 30px 0;
}
</style>
