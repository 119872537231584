<template>
    <Layout header-class="" :show-newsletter="true" :show-cta="false">
        <el-carousel :interval="5000">
            <el-carousel-item v-for="(item, index) in banner" :key="index">
                <div @click="banner_detail(item)">
                    <img
                        :src="item.image"
                        alt
                        style="width: 100%; height: auto; position: relative"
                    />
                    <p class="swiper">{{ item.title }}</p>
                </div>
            </el-carousel-item>
        </el-carousel>

        <div class="line"></div>
        <div class="rn-demo-area" id="demo">
            <div class="container">
                <div class="row" style="">
                    <div class="col-lg-12 col-md-12 col-12 bottom">
                        <div class="title">
                            <p class="title_">
                                {{ now_list.goods_cat1_book.name }}
                            </p>
                        </div>
                    </div>
                </div>
                <div class="row flex">
                    <div
                        class="white_card col-12"
                        v-for="(item, index) in now_list.goods_cat1_book.goods"
                        :key="item.id"
                        @click="detail(item)"
                    >
                        <img :src="item.image" alt="" />
                        <div class="book_title">
                            {{ item.name }}
                        </div>
                        <div class="money">￥{{ item.price }}</div>
                    </div>
                </div>
                <div class="row" style="">
                    <div class="col-lg-12 col-md-12 col-12 bottom">
                        <div class="title">
                            <p class="title_">
                                {{ now_list.goods_cat2_card.name }}
                            </p>
                        </div>
                    </div>
                </div>
                <div class="row flex">
                    <div
                        class="white_card col-12"
                        v-for="(item, index) in now_list.goods_cat2_card.goods"
                        @click="detail(item)"
                        :key="item.id"
                    >
                        <img :src="item.image" alt="" />
                        <div class="book_title">
                            {{ item.name }}
                        </div>
                        <div class="money">￥{{ item.price }}</div>
                    </div>
                </div>

                <div class="row" style="">
                    <div class="col-lg-12 col-md-12 col-12 bottom">
                        <div class="title">
                            <p class="title_">
                                {{ now_list.goods_cat3_other.name }}
                            </p>
                        </div>
                    </div>
                </div>
                <div class="row flex">
                    <div
                        class="white_card col-12"
                        v-for="(item, index) in now_list.goods_cat3_other.goods"
                        @click="detail(item)"
                        :key="item.id"
                    >
                        <img :src="item.image" alt="" />
                        <div class="book_title">
                            {{ item.name }}
                        </div>
                        <div class="money">￥{{ item.price }}</div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>

<script>
import Layout from "../components/common/Layout";
export default {
    name: "video",
    components: {
        Layout,
    },
    data() {
        return {
            id: "",
            model_id: "",
            page: 1,
            now_id: "",
            now_list: {},
            categories: [],
            banner: [],
        };
    },
    created() {
        this.id = this.$route.query.id;
        this.model_id = this.$route.query.model_id;
        this.list();
        this.bannerShow();
    },
    methods: {
        banner_detail(item) {
            this.$router.push({
                path: `/Shop_Detail?id=${item.id}`,
            });
        },
        bannerShow() {
            this.$http
                .get(
                    "https://cyfyjiaoxue.cn//api/index/getBannerList",
                    {
                        params: {
                            source_channel_id: this.id,
                        },
                    },
                    {
                        emulateJSON: true,
                    }
                )
                .then(
                    function (res) {
                        if (res.data.code == 200) {
                            this.banner = res.data.data;
                        } else if (res.data.code == 114) {
                            this.$alert(res.data.msg, "提示", {
                                confirmButtonText: "确定",
                                callback: (action) => {
                                    this.$router.push({
                                        path: `/login`,
                                    });
                                },
                            });
                        }
                    },
                    function (res) {
                        console.log(res); // 响应错误回调
                    }
                );
        },
        lookmore(item) {
            console.log(item);
            this.$router.push({
                path: "/videoDeatil",
                query: {
                    channel_id: item.id,
                },
            });
        },
        detail(item) {
            const { href } = this.$router.resolve({
                path: "/Shop_Detail",
                query: {
                    id: item.id,
                },
            });
            window.open(href);
            //关闭窗口的方法
            window.opener = null;
            window.open("", "_self", "");
            window.close();
        },
        // 列表
        list() {
            this.$http
                .get("https://cyfyjiaoxue.cn//api/goods/getGoodsList", {
                    emulateJSON: true,
                })
                .then(
                    function (res) {
                        if (res.data.code == 200) {
                            this.now_list = res.data.data;
                        } else if (res.data.code == 114) {
                            this.$alert(res.data.msg, "提示", {
                                confirmButtonText: "确定",
                                callback: (action) => {
                                    this.$router.push({
                                        path: `/login`,
                                    });
                                },
                            });
                        }
                    },
                    function (res) {
                        console.log(res); // 响应错误回调
                    }
                );
        },
    },
};
</script>
<style scoped>
.white {
    background-color: white;
    padding: 10px 0;
    border-radius: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
}

.white p {
    padding: 5px 10px;
    width: auto;
    border: 1px solid rgba(179, 171, 171, 1);
    border-radius: 5px;
    text-align: center;
    margin: 0 10px;
    margin-bottom: 15px;
}

.carding {
    background-color: white;
    border: none;
    border-radius: 10px;
}

.card_title {
    display: flex;
    justify-content: space-between;
    align-content: center;
    border-bottom: 1px solid rgba(227, 227, 227, 1);
    padding: 10px;
    color: rgba(10, 10, 10, 1);
    margin: 0 10px;
}

.card_content {
    padding: 10px 15px;
    border-bottom: 1px solid rgba(227, 227, 227, 1);
    display: flex;
    justify-content: space-between;
}

.card_content p.title {
    color: rgba(23, 109, 194, 1) !important;
    margin: 0;
    width: 70%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.card_content p.date {
    color: rgba(145, 141, 141, 1) !important;
    margin: 0;
}

.bottom {
    margin-bottom: 15px;
}

.line {
    width: 100%;
    height: 5vh;
}

.title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: black;
}

.title p {
    margin: 0;
}

.title .title_ {
    font-size: 22px;
    font-weight: 500;
    letter-spacing: 0px;
    color: rgba(0, 0, 0, 1);
    text-align: left;
    vertical-align: top;
}

.title .lookmore {
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0px;
    color: rgba(107, 107, 107, 1);
    text-align: left;
    vertical-align: top;
    border: none;
}

.cardWhite {
    background-color: white;
}

.cardTitle p {
    margin: 0;
}

.cardTitle {
    padding: 10px;
}

.cardTitle .title {
    font-size: 16px;
}

.cardTitle .desc {
    font-size: 12px;
    color: rgba(87, 87, 87, 1);
}

.first_tip {
    padding: 15px 10px;
    display: flex;
    justify-content: space-between;
}

.first_tip p {
    margin-bottom: 0;
}

.first_tip .money {
    color: rgba(97, 171, 255, 1);
    font-size: 16px;
}

.white_card {
    background-color: white;
    width: 18%;
    margin: 10px 1%;
    box-sizing: border-box;
    padding: 10px 20px;
    color: black;
}

.flex {
    display: flex;
}

.book_title {
    margin-top: 10px;
    font-size: 14px;
    font-weight: 400;
    /* line-height: 28px; */
    color: rgba(0, 0, 0, 1);
    text-align: left;
    vertical-align: top;
}

.money {
    font-size: 22.54px;
    font-weight: 500;
    letter-spacing: 0px;
    line-height: 40px;
    color: rgba(255, 0, 0, 1);
    text-align: center;
    vertical-align: top;
}

/* <768px */
@media screen and (max-width: 768px) {
    .white_card {
        width: 95%;
        margin: 10px auto;
    }
}

/* >=992 and  */
@media screen and (min-width: 992px) {
}

/* >=768px and <1200px */
@media screen and (min-width: 768px) and (max-width: 1200px) {
}

/* >=1200px */
@media screen and (min-width: 1200px) {
}
</style>
